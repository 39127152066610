import gsap from 'gsap';

import $ from '../core/Dom';
import superagent from '../core/request';
import Components from '../core/Components';
import Dispatch from '../core/Dispatch';

import i18n from '../lib/i18n';
import Revealer from '../lib/Revealer';

import { COMPONENT_INIT } from '../lib/events';

export default (el, { filterCookieName }) => {

    const $el = $(el);
    const $announcer = $el.find('[data-announcer]');
    const $loadMoreBtn = $el.find('[data-loadmore-btn]');

    let activeFilter;
    let request;

    const setFilterCookie = () => {
        if (!filterCookieName) {
            return;
        }
        let value = activeFilter ? activeFilter.dataset.filterbtn : null;
        if (!value || value === 'all') {
            value = '';
        } else {
            value = value.split('/').pop();
        }
        window.vrsg.setCookie(filterCookieName, value);
        console.log('set filter cookie', { filterCookieName, value });
    };

    const announce = text => $announcer.text(text);

    const enableLoadMore = () => {
        $loadMoreBtn.get(0).removeAttribute('aria-disabled');
        $loadMoreBtn.get(0).hidden = false;
    };

    const disableLoadMore = () => {
        $loadMoreBtn.get(0).setAttribute('aria-disabled', 'true');
        if (document.activeElement !== $loadMoreBtn.get(0)) {
            $loadMoreBtn.get(0).hidden = true;
        }
    };

    const addItems = $html => {
        const $newItems = $html.find('[data-loadmore-item]');
        if (!$newItems.length) {
            return;
        }
        const $container = $el.find('[data-items]');
        $container.append($newItems);
        announce(i18n('Added {count} items to the list', { count: $newItems.length }));
        Components.init($container.get(0));
        Revealer.update();
    };

    const replaceListing = $html => {
        const $newListing = $html.find('[data-listing]');
        const listing = $el.find('[data-listing]').get(0);
        gsap.timeline()
            .to(listing, { opacity: 0, duration: 0.3 })
            .add(() => {
                Components.destroy(listing);
                $(listing).html($newListing.length ? $newListing.html() : '');
                Components.init(listing);
                Revealer.update();
            })
            .to(listing, { opacity: 1, duration: 0.3 });
    };

    const updateLoadMoreButton = $newLoadMoreBtn => {
        if (!$newLoadMoreBtn || $newLoadMoreBtn.attr('aria-disabled') === 'true' || !!$newLoadMoreBtn.get(0).hidden) {
            disableLoadMore();
        } else {
            $loadMoreBtn.attr('href', $newLoadMoreBtn.attr('href'));
            enableLoadMore();
        }
    };

    const setActiveFilter = () => {
        const currentPath = window.location.pathname.replace(/^\/|\/$/g, '');
        activeFilter = $el.find(`[data-filterbtn="${currentPath}"]`).get(0) || $el.find('[data-filterbtn="all"]').get(0);
        setFilterCookie();
        if (!activeFilter || !$(activeFilter).hasClass('inactive')) {
            return;
        }
        $el.find('[data-filterbtn]').addClass('inactive');
        $(activeFilter).removeClass('inactive');
    };

    const updateList = (url, append = false) => {
        if (request) {
            request.abort();
        }
        $el.addClass('js-is-loading');
        request = superagent.get(url, { cache: true, ajax: true });
        request
            .then(({ status, text }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }
                const $html = $('<div/>').html(text);
                if (append) {
                    addItems($html);

                } else {
                    replaceListing($html);
                }
                updateLoadMoreButton($html.find('[data-loadmore-btn]'));
                Revealer.update();
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                request = null;
                $el.removeClass('js-is-loading');
            });
    };

    const onLoadMoreClick = e => {
        e.preventDefault();
        if (!!request || $loadMoreBtn.attr('aria-disabled')) {
            return;
        }
        const url = $loadMoreBtn.attr('href');
        if (!url) {
            return;
        }
        updateList(url, true);
    };

    const onFilterClick = e => {
        e.preventDefault();
        const { triggerTarget: target } = e;
        const url = $(target).attr('href');
        window.history.pushState(null, null, url);
        updateList(url, false);
        setActiveFilter();
    };

    const onPopState = () => {
        updateList(window.location.href, false);
        setActiveFilter();
    };

    const init = () => {
        $el
            .on('click', '[data-loadmore-btn]', onLoadMoreClick)
            .on('click', '[data-filterbtn]', onFilterClick);
        window.addEventListener('popstate', onPopState);
        setActiveFilter();
        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        $el.off('click');
        window.removeEventListener('popstate', onPopState);
    };

    return {
        init,
        destroy
    };

};

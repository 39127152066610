import $ from '../core/Dom';

import Popup from '../lib/Popup';

export default el => {

    const $el = $(el);

    let popup;

    const onClick = e => {
        e.preventDefault();
        if (!popup) {
            popup = Popup(el.nextElementSibling, el);
        }
        popup.show();
    };

    const init = () => {
        $el.attr({
            role: 'button',
            'aria-expanded': 'false',
            tabIndex: '0'
        }).get(0).removeAttribute('href');
        $el.on('keydown', e => {
            const key = e.key || e.which || e.keyCode || null;
            const isSpace = [' ', 32].indexOf(key) > -1;
            const isEnter = ['Enter', 13].indexOf(key) > -1;
            if (!isSpace && !isEnter) {
                return;
            }
            onClick(e);
        });
        $el.on('click', onClick);
    };

    const destroy = () => {
        $el.off('click keydown');
        if (popup) {
            popup.destroy();
        }
    };

    return {
        init,
        destroy
    };

};

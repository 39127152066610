import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

/**
 *
 * @param el
 * @returns {{init: init, destroy: destroy}}
 */
export default el => {

    const onResize = () => {
        const images = $(el).find('img').get();
        let imageHeight = null;
        let lastTop = null;
        $(images).height('');
        images.forEach(image => {
            const { top, height } = image.parentNode.getBoundingClientRect();
            // Is this the first image in this row?
            if (lastTop === null || top > lastTop) {
                imageHeight = Math.round(height);
            }
            $(image).height(imageHeight);
            lastTop = top;
        });
    };

    return {
        init: () => {
            Viewport.on('resize', onResize);
            onResize();
            Dispatch.emit(COMPONENT_INIT);
        },
        destroy: () => {
            Viewport.off('resize', onResize);
        }
    };

};

import gsap from 'gsap';

import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';

import { COMPONENT_INIT } from '../lib/events';

require('intersection-observer');

export default el => {

    const $el = $(el).eq(0);

    const $container = $el.parent().eq(0);
    const $image = $el.is('img') || $el.attr('role') === 'img' ? $el : $container.find('img,[role="img"]').eq(0);
    const $video = $container.find('video').eq(0);
    const videoSrc = $video.attr('data-src');
    const video = $video.get(0);

    let observer;
    let videoIsPlaying = false;
    let fallbackTimer = null;
    let fallbackHandler;

    if (['relative', 'absolute'].indexOf($container.css('position')) === -1) {
        $container.css({ position: 'relative', overflow: 'hidden', zIndex: 1 });
    }

    if ($image.length) {
        $image.css({ visibility: 'hidden' });
    }

    const stopFallbackTimer = () => {
        if (!fallbackTimer) {
            return;
        }
        clearTimeout(fallbackTimer);
        fallbackTimer = null;
    };

    const onProgressEvent = () => {
        if (videoIsPlaying) {
            return;
        }
        stopFallbackTimer();
        videoIsPlaying = true;
        gsap.fromTo(video, { opacity: 0 }, { opacity: 1, duration: 0.5, ease: 'Quad.easeOut' });
        console.log('video is playing, probably');
    };

    const onLoadEvent = () => {
        if (videoIsPlaying) {
            return;
        }
        stopFallbackTimer();
        fallbackTimer = setTimeout(fallbackHandler, 6000);
        console.info('load start, new timer');
    };

    const removeVideoEventListeners = () => {
        video.removeEventListener('timeupdate', onProgressEvent);
        video.removeEventListener('canplay', onProgressEvent);
        video.removeEventListener('loadstart', onLoadEvent);
        video.removeEventListener('loadedmetadata', onLoadEvent);
        video.removeEventListener('loadeddata', onLoadEvent);
    };

    const killVideo = () => {
        stopFallbackTimer();
        removeVideoEventListeners();
        try {
            video.pause();
        } catch (error) {}
        try {
            video.hidden = true;
        } catch (error) {}
        videoIsPlaying = false;
        console.log('video killed');
    };

    const cantPlayVideo = () => {
        console.log(':: cant play video ::');
        if ($image.length && $image.hasClass('lzld')) {
            $image.removeClass('lzld').addClass('lazyload lazypreload').css({ visibility: '' });
        }
        stopFallbackTimer();
        killVideo();
    };

    const onFallbackTimer = () => {
        console.info('fallback timer');
        cantPlayVideo();
    };

    const addVideoEventListeners = () => {
        video.addEventListener('timeupdate', onProgressEvent);
        video.addEventListener('canplay', onProgressEvent);
        video.addEventListener('loadstart', onLoadEvent);
        video.addEventListener('loadedmetadata', onLoadEvent);
        video.addEventListener('loadeddata', onLoadEvent);
    };

    const playAndCatch = () => {

        video.src = videoSrc;
        video.muted = true;

        gsap.set(video, { opacity: 0 });

        video.hidden = false;

        addVideoEventListeners();

        fallbackTimer = setTimeout(fallbackHandler, 1000);

        const promise = video.play();

        if (promise !== undefined) {
            promise.then(() => {
                stopFallbackTimer();
            }).catch(e => {
                if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                    cantPlayVideo();
                }
            });
        }

        console.info('play and catch');

    };

    fallbackHandler = onFallbackTimer;

    const init = () => {
        if (!videoSrc || !video.canPlayType || !video.canPlayType('video/mp4')) {
            cantPlayVideo();
            return;
        }
        observer = new IntersectionObserver(entries => {
            const { isIntersecting } = entries[0];
            if (!isIntersecting) {
                return;
            }
            console.info('video is intersecting');
            playAndCatch();
            observer.unobserve(el);
            observer.disconnect();
            observer = null;
        }, {
            rootMargin: '1000px 0px 1000px 0px'
        });
        observer.observe(el);
        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        if (observer) {
            observer.disconnect();
        }
        killVideo();
        console.info('video destroyed');
    };

    return {
        init,
        destroy
    };

};

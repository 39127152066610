export default (el, { cookieName, id }) => {

    let cookie = window.vrsg.getCookie(cookieName);

    cookie = (cookie || '').split(',');

    const seenIds = [parseInt(id, 10)];
    cookie.forEach(value => {
        const seenId = parseInt(value, 10);
        if (!!seenId && seenIds.indexOf(seenId)) {
            seenIds.push(seenId);
        }
    });

    cookie = seenIds.join(',');

    window.vrsg.setCookie(cookieName, cookie);

};

import gsap from 'gsap';
import $ from '../core/Dom';

export default el => {

    const $el = $(el);

    const toggle = panelId => {
        const panel = $el.find(`#${panelId}`).get(0);
        if (!panel) {
            return;
        }
        const btn = $el.find(`[aria-controls="${panelId}"]`).get(0);
        if (!btn) {
            return;
        }
        const isExpanded = btn.getAttribute('aria-expanded') === 'true';
        const tween = $(panel).data('_tween');
        if (!isExpanded) {
            btn.setAttribute('aria-expanded', 'true');
            tween.play();
        } else {
            btn.setAttribute('aria-expanded', 'false');
            tween.reverse();
        }
    };

    const onToggleClick = e => {
        e.preventDefault();
        const { triggerTarget: target } = e;
        toggle(target.getAttribute('aria-controls'));
    };

    const init = () => {
        const isLivePreview = $('html').hasClass('is-live-preview');
        $el.find('[aria-controls]').each(btn => {
            const panelId = btn.getAttribute('aria-controls');
            const panel = $el.find(`#${panelId}`).get(0);
            if (!panel) {
                return;
            }
            const $btn = $(btn);
            const tl = gsap.timeline({
                paused: true,
                onStart: () => { panel.hidden = false; },
                onReverseComplete: () => { panel.hidden = true; }
            })
                .fromTo(panel, { height: 0 }, { height: 'auto', duration: 0.35, ease: 'Cubic.easeInOut' }, 0)
                .fromTo(panel.firstElementChild, { y: 20 }, { y: 0, duration: 0.35, ease: 'Cubic.easeInOut' }, 0)
                .fromTo(panel.firstElementChild, { opacity: 0 }, { opacity: 1, duration: 0.5, ease: 'Cubic.easeInOut' }, 0)
                .to($btn.find('[data-icon]').get(0), { rotate: 180, duration: 0.5, ease: 'Cubic.easeInOut' }, 0)
                .to($btn.find('[data-icon] > span:last-child').get(0), { rotation: 90, duration: 0.5, ease: 'Cubic.easeInOut' }, 0);
            $(panel).data('_tween', tl);
            const shouldBeExpanded = !isLivePreview && panelId === window.location.hash;
            if (shouldBeExpanded) {
                toggle(panelId);
            }
        });
        $el.on('click', '[aria-controls]', onToggleClick);
    };

    const destroy = () => {
        $el.off('click');
    };

    return {
        init,
        destroy
    };

};

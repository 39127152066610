import ResizeObserver from 'resize-observer-polyfill';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

let observer;

const getCssFontSize = el => parseInt(getComputedStyle(el).getPropertyValue('font-size').replace('px', ''), 10);

const getFontSizes = el => {
    const rawValues = {
        min: el.dataset.fittextMin || 32,
        max: el.dataset.fittextMax || 200
    };
    const { name: currentBreakpoint } = Viewport.breakpoint;
    let breakpoints = ['s', 'sp', 'm', 'mp', 'l', 'lp', 'xl'];
    breakpoints = breakpoints.slice(0, breakpoints.indexOf(currentBreakpoint) + 1).reverse().concat(['min']);
    return Object.keys(rawValues).reduce((carry, key) => {
        let fontSizes = JSON.parse(rawValues[key]);
        if (!Object.keys(fontSizes).length) {
            fontSizes = { min: fontSizes };
        }
        let fontSize;
        for (let i = 0; i < breakpoints.length; i++) {
            if (fontSizes.hasOwnProperty(breakpoints[i])) {
                fontSize = fontSizes[breakpoints[i]];
                break;
            }
        }
        return {
            ...carry,
            [key]: fontSize
        };
    }, {});
};

const fit = el => {

    let { min: minFontSize, max: maxFontSize } = getFontSizes(el);

    if (!minFontSize && !maxFontSize) {
        throw new Error('Neither min or max font size is set');
    }

    minFontSize = parseInt(minFontSize, 10) || getCssFontSize(el);
    maxFontSize = parseInt(maxFontSize, 10) || getCssFontSize(el);

    const span = el.firstElementChild;
    if (!span) {
        return;
    }
    span.style.whiteSpace = 'nowrap';

    const { clientWidth } = el;

    // Figure out the font-size based on width
    let low = minFontSize;
    let high = maxFontSize;
    let mid = 0;
    let size = low;
    while (low <= high) {
        mid = (high + low) >> 1;
        span.style.fontSize = `${mid}px`;
        if (span.scrollWidth <= clientWidth) {
            size = mid;
            low = mid + 1;
        } else {
            high = mid - 1;
        }
    }

    if (size <= minFontSize) {
        // We're below the minimum desired font size
        // Allow text wrapping
        span.style.whiteSpace = '';
        // If the span is still too wide, scale it down further. 20 pixels represent the absolute minimum font size allowed
        while (span.scrollWidth > clientWidth) {
            size -= 1;
            span.style.fontSize = `${size}px`;
        }
    } else if (size >= maxFontSize) {
        span.style.fontSize = `${maxFontSize}px`;
    } else {
        span.style.fontSize = `${size}px`;
    }

};

const onResize = entries => {
    if (!Array.isArray(entries) || !entries.length) {
        return;
    }
    entries.forEach(entry => {
        fit(entry.target);
    });
};

const init = () => {
    if (!observer) {
        observer = new ResizeObserver(onResize);
    }
    const $els = $('[data-fittext]:not(.js-fitted)');
    $els.addClass('js-fitted').each(el => {
        // Wrap content in a span
        $(el).html(`<span style="display:inline-block;">${$(el).html().trim()}</span>`);
        fit(el);
        observer.observe(el);
    });
};

const update = () => {
    init();
};

export default {
    init,
    update
};

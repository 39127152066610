import gsap from 'gsap';

import $ from '../core/Dom';
import superagent from '../core/request';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import Components from '../core/Components';
import Config from '../core/Config';

import i18n from '../lib/i18n';
import Revealer from '../lib/Revealer';

import { COMPONENT_INIT, LISTING_FILTERS_CHANGE } from '../lib/events';

/**
 * @param el
 * @param props
 * @returns {{init: init, destroy: destroy}}
 */
export default (el, props) => {

    const { url, filter: currentFilter = null, relatedTo, params } = props || {};

    const $el = $(el);
    const $announcer = $el.find('[data-announcer]').eq(0);
    const filters = $el.find('[data-component="ListFilters"]').get(0);
    const loadMoreBtn = $el.find('[data-loadmore-btn]').get(0);

    let request;
    let currentPage;
    let totalPages;

    let filter = currentFilter;
    let searchQuery = null;

    const announce = text => $announcer.text(text);

    const maybeDisableLoadMore = () => {
        if (currentPage >= totalPages) {
            // Disable and (maybe) hide the loadmore button
            loadMoreBtn.setAttribute('aria-disabled', 'true');
            if (document.activeElement !== loadMoreBtn) {
                loadMoreBtn.hidden = true;
            }
        } else {
            loadMoreBtn.removeAttribute('aria-disabled');
            loadMoreBtn.hidden = false;
        }
    };

    const resetPagination = () => {
        currentPage = 1;
        totalPages = 1;
    };

    const appendToList = $html => {
        const $newItems = $html.find('[data-loadmore-item]');
        if (!$newItems.length) {
            return;
        }
        // Append any new items returned
        const $container = $(el).find('[data-list]').eq(0);
        $container.append($newItems);
        Components.init($container.get(0));
        announce(i18n('Added {count} items to the list', { count: $newItems.length }));
        maybeDisableLoadMore();
        Revealer.update();
    };

    const replaceList = $html => {
        // Replace everything, but only if the content is actually different
        const $newItems = $html.find('[data-loadmore-item]');
        const $container = $(el).find('[data-list]').eq(0);
        const oldItemIds = $container.find('[data-loadmore-item]').get().map(item => $(item).data('loadmore-item')).join('');
        const newItemIds = $newItems.get().map(item => $(item).data('loadmore-item')).join('');
        if (newItemIds === oldItemIds) {
            return;
        }
        const container = $container.get(0);
        gsap.set(container, { height: $container.height(), overflow: 'hidden' });
        gsap.timeline()
            .to(container, { opacity: 0, duration: 0.3 })
            .add(() => {
                Components.destroy(container);
                const newHtml = $('<div />').append($newItems).html() || '';
                $container.html(newHtml);
                Components.init(container);
                maybeDisableLoadMore();
                Revealer.update();
                gsap.timeline()
                    .to(container, { height: 'auto', duration: 0.3 })
                    .set(container, { clearProps: 'height,overflow' });
            })
            .to(container, { opacity: 1, duration: 0.3 });
    };

    /**
     * Do an AJAX call that updates the list as per the current offset, filters and search query
     * @param append
     */
    const updateList = (append = false) => {
        if (request) {
            request.abort();
        }
        $el.addClass('js-is-loading');
        let endpoint = url || window.location.href;
        if (currentPage > 1) {
            endpoint = endpoint.replace(/\/$/, '');
            endpoint = `${endpoint}/${Config.get('pageTrigger')}${currentPage}`;
        }
        const query = {};
        if (searchQuery) {
            query.search = searchQuery;
        } else if (filter) {
            query.filter = filter;
        }
        if (relatedTo) {
            query.relatedTo = JSON.stringify(relatedTo);
        }
        if (params) {
            query.params = JSON.stringify(params);
        }
        request = superagent.get(endpoint, { cache: true, ajax: true });
        request
            .query(query)
            .then(({ status, text }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }
                const $html = $('<div/>').html(text);
                // Update total and offset
                currentPage = $html.find('[data-current-page]').data('current-page') || 1;
                totalPages = $html.find('[data-total-pages]').data('total-pages') || 1;
                requestAnimationFrame(() => {
                    if (append) {
                        appendToList($html);
                    } else {
                        replaceList($html);
                    }
                });
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                request = null;
                $el.removeClass('js-is-loading');
            });
    };

    const loadMore = () => {
        if (request || currentPage >= totalPages) {
            return;
        }
        currentPage += 1;
        updateList(true);
    };

    const onLoadMoreBtnClick = e => {
        e.preventDefault();
        loadMoreBtn.focus();
        loadMore();
    };

    const onLoadMoreBtnKey = e => {
        loadMoreBtn.focus();
        const key = e.key || e.which || e.keyCode || null;
        const isSpace = [' ', 32].indexOf(key) > -1;
        const isEnter = ['Enter', 13].indexOf(key) > -1;
        if (!isSpace && !isEnter) {
            return;
        }
        if (e.type === 'keydown') {
            e.preventDefault();
            return;
        }
        loadMore();
    };

    const onListingFiltersChange = (key, data) => {
        const { id, searchQuery: newSearchQuery = null, filter: newFilter = null } = data;
        if (id !== filters.id) {
            return;
        }
        if (newSearchQuery === searchQuery && newFilter === filter) {
            return;
        }
        searchQuery = newSearchQuery;
        filter = newFilter;
        resetPagination();
        // If the top of the list is not in the viewport, scroll to it
        const { top } = el.getBoundingClientRect();
        if (top < 0) {
            const scrollTop = Viewport.scrollTop + top;
            gsap.to($('html,body').get(), { scrollTop, duration: 0.5, ease: 'Quad.easeOut' });
        }
        updateList(false);
    };

    const init = () => {
        currentPage = $el.find('[data-current-page]').data('current-page') || 1;
        totalPages = $el.find('[data-total-pages]').data('total-pages') || 1;
        $el
            .on('click', '[data-loadmore-btn]', onLoadMoreBtnClick)
            .on('keydown keyup', '[data-loadmore-btn]', onLoadMoreBtnKey);
        Dispatch.on(LISTING_FILTERS_CHANGE, onListingFiltersChange);
        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        $el.off('click keydown keyup');
        Dispatch.off(LISTING_FILTERS_CHANGE, onListingFiltersChange);
    };

    return {
        init,
        destroy
    };

};

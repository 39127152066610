import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';

import { COMPONENT_INIT, VIEWPORTHEIGHT_CHANGE } from '../lib/events';

export default el => {

    const words = $(el).find('[data-word],span:not([data-word])').get();

    if (!words.length) {
        return null;
    }

    const bottomMargin = 150;

    let currentScrollTop = -1;
    let tween = false;
    let direction = 'down';

    let tl;
    let isAboveFold = false;
    let viewH = Viewport.height;

    const createTimeline = () => {

        if (tl) {
            tl.kill();
        }

        const { height } = el.getBoundingClientRect();
        const pixelsPerWord = height / (words.length + 2);

        const x = 0;
        const y = 0;

        gsap.set(words, { opacity: 0, x, y });

        tl = gsap.timeline({ paused: true }).add(() => {}, 'word');

        words.forEach((word, index) => {
            tl.add(() => {
                gsap.killTweensOf(word);
                if (direction === 'down') {
                    if (tween) {
                        gsap.timeline()
                            .to(word, { opacity: 1, duration: 0.5, ease: 'Quad.easeInOut', force3D: true }, 0);
                    } else {
                        gsap.set(word, { opacity: 1 });
                    }
                    return;
                }
                // Direction up
                if (tween) {
                    gsap.timeline()
                        .to(word, { opacity: 0, force3D: true, duration: 0.3 })
                        .set(word, { opacity: 0 });
                } else {
                    gsap.set(word, { opacity: 0 });
                }
            }, `word+=${pixelsPerWord * (index + 1)}`);
        });

        tl.add(() => {}, `end+=${pixelsPerWord}`);
    };

    const setProgress = () => {
        if (isAboveFold) {
            tl.pause(tl.duration(), false);
            return;
        }

        const { top, height } = el.getBoundingClientRect();
        const scrollTop = (top + bottomMargin) - viewH;

        direction = scrollTop < currentScrollTop ? 'down' : 'up';
        currentScrollTop = scrollTop;

        if (!tl) {
            return;
        }

        let progress = (scrollTop / height) * -1;
        if (progress < 0) {
            progress = 0;
        } else if (progress > 1) {
            progress = 1;
        }

        const time = tl.duration() * progress;
        tl.pause(time, false);
    };

    const onScroll = () => {
        setProgress();
    };

    const onResize = () => {
        tween = false;
        createTimeline();
        // Is this element above the fold?
        isAboveFold = $(el).offset().top < viewH;
        const { top } = el.getBoundingClientRect();
        if (top - viewH < 0) {
            currentScrollTop = -1;
        }
        setProgress();
        tween = !isAboveFold;
    };

    const onViewportHeightChange = (key, data) => {
        viewH = data;
    };

    const init = () => {
        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);
        Dispatch.on(VIEWPORTHEIGHT_CHANGE, onViewportHeightChange, true);
        onResize();
        el.classList.add('js-init');
        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        if (tl) tl.kill();
        Viewport.off('resize', onResize);
        Viewport.off('scroll', onScroll);
        Dispatch.off(VIEWPORTHEIGHT_CHANGE, onViewportHeightChange);
    };

    return {
        init,
        destroy
    };

};

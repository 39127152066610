import gsap from 'gsap';

import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';

import { VIEWPORTHEIGHT_CHANGE } from './events';

let hasInited = false;
let nodes = [];
let elements;
let raf;
let viewportHeight;

const parallax = () => {
    const { scrollTop } = Viewport;
    nodes.forEach(node => {
        const { offset, distance } = elements.get(node);
        const objectDistanceFromViewportCenter = offset - (scrollTop + (viewportHeight / 2));
        const moveDistance = (viewportHeight * (distance / 100));
        const y = (objectDistanceFromViewportCenter / viewportHeight) * moveDistance;
        gsap.set(node, { y });
    });
    raf = null;
};

const onScroll = () => {
    if (raf) {
        cancelAnimationFrame(raf);
    }
    raf = requestAnimationFrame(parallax);
};

const update = () => {
    elements = new WeakMap();
    nodes = $('[data-parallax]').get();
    if (!nodes.length) {
        return;
    }
    const { name: breakpoint } = Viewport.breakpoint;
    let breakpoints = ['s', 'sp', 'm', 'mp', 'l', 'lp', 'xl'];
    breakpoints = breakpoints.slice(0, breakpoints.indexOf(breakpoint) + 1).reverse().concat(['min']);
    nodes.forEach(node => {
        // Find the distance/factor for the current breakpoint
        const values = node.dataset.parallax.split(',').reduce((carry, value) => {
            const temp = value.split(':');
            return {
                ...carry,
                [temp[0] || 'min']: temp[1] || temp[0] || null
            };
        }, {});
        const distance = breakpoints.reduce((carry, bp) => carry || parseFloat(values[bp] || 0), 0);
        elements.set(node, {
            offset: $(node).parent().offset().top + ($(node).parent().height() * 0.5),
            distance
        });
    });
    console.info('parallax nodes created');
};

const init = () => {
    if (!hasInited) {
        // Trigger the parallax scroll handler on... scroll
        Viewport.on('scroll', onScroll);
        // When the breakpoint changes, update the elements and trigger the parallax scroll handler
        Viewport.on('breakpoint', () => {
            update();
            onScroll();
        });
        // When the viewport height changes, cache the viewport height value and trigger the parallax scroll handler
        Dispatch.on(VIEWPORTHEIGHT_CHANGE, () => {
            viewportHeight = Viewport.height;
            onScroll();
        });
        hasInited = true;
    }
    viewportHeight = Viewport.height;
    update();
    onScroll();
};

export default {
    init,
    update: init
};

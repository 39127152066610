import gsap from 'gsap';
import serialize from 'form-serialize';

import $ from '../core/Dom';
import request from '../core/request';
import Dispatch from '../core/Dispatch';
import Revealer from '../lib/Revealer';
import { COMPONENT_INIT } from '../lib/events';
import { dispatchEvent } from '../lib/helpers';

export default form => {

    const $form = $(form);
    const $submit = $form.find('button[type="submit"]');
    const spinner = $form.find('[data-submit-spinner]').get(0);
    const submitLabel = $form.find('[data-submit-label]').get(0);
    const receipt = $form.find('[data-receipt]').get(0);

    let isSubmitting = false;
    let defaultError;

    const refreshTimestamp = () => {
        const timestampInput = $form.find('input[type="hidden"][name="now"]').get(0);
        if (!timestampInput) {
            return;
        }
        timestampInput.setAttribute('value', Math.floor((new Date().getTime()) / 1000));
    };

    const hideReceipt = () => {
        if (!receipt) return;
        receipt.hidden = true;
    };

    const showReceipt = () => {
        // Reset form
        $form.find('input:not([type="hidden"]),textarea,select').each(input => {
            if (input.type === 'checkbox' || input.type === 'radio') {
                input.checked = false;
                return;
            }
            input.value = '';
            $(input).parent('.js-has-value').removeClass('js-has-value');
        });
        // Reset timestamp
        refreshTimestamp();
        if (receipt) {
            receipt.hidden = false;
            // If the bottom of the receipt is not in the viewport, scroll to it

        }
        Revealer.update();
    };

    const clearErrors = () => {
        const errorMessage = $form.find('[data-error]').get(0);
        if (errorMessage) {
            errorMessage.hidden = true;
        }
        $form.find('[data-input].error').each(node => {
            $(node).removeClass('error');
            $(node).find('[data-errors]').html('').get(0).hidden = true;
        });
        Revealer.update();
    };

    const setErrors = errors => {
        clearErrors();

        const errorKeys = Object.keys(errors);

        errorKeys.forEach(key => {
            const $input = $form.find(`[data-input="${key}"]`);
            if (!$input.length) {
                return;
            }
            $input.addClass('error');
            const $errors = $input.find('[data-errors]');
            if (!$errors.length) {
                return;
            }
            $errors.html([].concat(errors[key]).join('<br/>'));
            $errors.get(0).hidden = false;
        });

        const firstErrorInput = $form.find('[data-input].error').find('input:not([type="hidden"]),textarea,select').get(0);
        if (firstErrorInput) {
            try {
                firstErrorInput.focus();
                firstErrorInput.select();
            } catch (error) {
                console.error(error);
            }
        }
    };

    const showErrorMessage = message => {
        const errorMessage = $form.find('[data-error]').get(0);
        if (!errorMessage) {
            return;
        }
        errorMessage.hidden = false;
        if (message) {
            if (!defaultError) {
                defaultError = $(errorMessage).find('[data-message]').html();
            }
            $(errorMessage).find('[data-message]').html(`<p>${message}</p>`);
        } else if (defaultError) {
            $(errorMessage).find('[data-message]').html(defaultError || '');
        }
    };

    const disableSubmit = () => {
        $submit.get(0).setAttribute('aria-disabled', 'true');
    };

    const enableSubmit = () => {
        $submit.get(0).removeAttribute('aria-disabled');
    };

    const onSubmit = e => {

        e.preventDefault();

        if (isSubmitting) {
            return;
        }

        isSubmitting = true;

        const data = serialize($form.get(0));

        disableSubmit();
        hideReceipt();

        const hasSpinner = spinner && submitLabel;
        if (hasSpinner) {
            spinner.hidden = false;
            gsap.set(submitLabel, { visibility: 'hidden' });
        }

        const url = window.location.href.split('?') || '/';

        request
            .post(url)
            .accept('application/json')
            .send(data)
            .then(({ status }) => {
                if (status !== 200) {
                    throw new Error();
                }
                clearErrors();
                showReceipt();
                dispatchEvent(form, 'success');
            })
            .catch(error => {
                const { response = {} } = error || {};
                const { errors, message = null } = response.body || {};
                if (errors) {
                    setErrors(errors);
                } else {
                    showErrorMessage(message);
                }
            })
            .catch(error => {
                console.error(error);
                showErrorMessage();
            })
            .then(() => {
                if (hasSpinner) {
                    spinner.hidden = true;
                    gsap.set(submitLabel, { visibility: '' });
                }
                isSubmitting = false;
                enableSubmit();
            });
    };

    const init = () => {
        $form.on('submit', onSubmit);
        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        $form.off('submit', onSubmit);
    };

    return {
        init,
        destroy
    };
};

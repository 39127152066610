import gsap from 'gsap';

import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Config from '../core/Config';
import Dispatch from '../core/Dispatch';

import { COMPONENT_INIT } from '../lib/events';

export default el => {

    const $el = $(el);
    const menu = $el.find('#nav').get(0);
    const items = $(menu).find('[data-item]').get();
    const burger = $el.find('[aria-controls="nav"]').get(0);

    let activeElement;

    const isScreenSmall = () => ['l', 'lp', 'xl'].indexOf(Viewport.breakpoint.name) === -1;

    const isMenuOpen = () => !isScreenSmall() || burger.getAttribute('aria-expanded') === 'true';

    const openMenu = (tween = true) => {
        if (isMenuOpen()) {
            return;
        }
        activeElement = document.activeElement || null;
        $(menu).removeClass('hidden');
        burger.setAttribute('aria-expanded', 'true');
        Viewport.lockTabbing(el, burger);
        const tl = gsap.timeline({ paused: true })
            .fromTo(menu, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 'in')
            .fromTo(items, { opacity: 0 }, {
                opacity: 1,
                duration: 0.5,
                ease: 'Cubic.easeIn',
                stagger: 0.1
            }, 'in+=0.1')
            .fromTo(items, { y: 100 }, {
                y: 0,
                duration: 1,
                ease: 'Quint.easeOut',
                stagger: 0.1,
                snap: 'y'
            }, 'in+=0.1');
        if (!tween || Config.get('prefersReducedMotion')) {
            tl.pause(tl.duration(), false);
            return;
        }
        tl.play();
    };

    const closeMenu = (tween = true) => {
        if (!isMenuOpen()) {
            return;
        }
        Viewport.releaseTabbing(el, activeElement || burger);
        activeElement = null;
        burger.setAttribute('aria-expanded', 'false');
        const tl = gsap.timeline({
            paused: true,
            onComplete() {
                $(menu).addClass('hidden');
                menu.scrollTop = 0;
                gsap.set([menu].concat(items || []), { clearProps: 'all' });
            }
        })
            .to(menu, { opacity: 0, duration: 0.3 });
        if (!tween || Config.get('prefersReducedMotion')) {
            tl.pause(tl.duration(), false);
            return;
        }
        tl.play();
    };

    const toggleMenu = e => {
        if (e) {
            e.preventDefault();
        }
        if (!isMenuOpen()) {
            openMenu();
        } else {
            closeMenu();
        }
    };

    const onBreakpoint = () => {
        requestAnimationFrame(() => {
            if (!isScreenSmall() && isMenuOpen()) {
                closeMenu(false);
            }
        });
    };

    const onBodyKeyUp = e => {
        if (!isMenuOpen() || !isScreenSmall()) {
            return;
        }
        const key = e.key || e.which || e.keyCode || null;
        if (['Escape', 27].indexOf(key) > -1) {
            closeMenu();
        }
    };

    const init = () => {

        if (burger) {
            burger.removeAttribute('href');

            $(burger)
                .on('click', toggleMenu)
                .on('keyup', e => {
                    const key = e.key || e.which || e.keyCode || null;
                    if (['Enter', 'Space', 13, 32].indexOf(key) === -1) {
                        return;
                    }
                    toggleMenu();
                });
        }

        Viewport.on('breakpoint', onBreakpoint);

        $('body').on('keyup', onBodyKeyUp);

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        Viewport.off('breakpoint', onBreakpoint);
        if (burger) {
            $(burger).off('click keyup');
        }
        $('body').off('keyup', onBodyKeyUp);
        closeMenu(false);
    };

    return {
        init,
        destroy
    };

};

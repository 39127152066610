import Config from '../core/Config';

export default (key, vars = {}) => {
    let value = Config.get(`i18n.${key}`) || key;
    Object.keys(vars).forEach(name => {
        const pattern = `{${name}}`;
        value = value.replace(new RegExp(pattern, 'g'), vars[name]);
    });
    return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
};

import $ from '../core/Dom';
import request from '../core/request';
import Components from '../core/Components';
import Dispatch from '../core/Dispatch';

import { COMPONENT_INIT } from '../lib/events';

export default (el, { entryUid: uid, endpoint }) => {

    let observer;

    const loadHtml = () => {
        console.info('load related content');
        request
            .get(endpoint, { cache: true, ajax: true })
            .query({ uid })
            .then(({ status, text: html }) => {
                if (status !== 200 || !html) {
                    throw Error();
                }
                const relatedContent = $(html).get(0);
                el.parentNode.insertBefore(relatedContent, el);
                $(el).remove();
                Components.init(relatedContent);
                Dispatch.emit(COMPONENT_INIT);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const unobserve = () => {
        if (!observer) {
            return;
        }
        observer.disconnect();
        observer = null;
    };

    const observe = () => {
        observer = new IntersectionObserver(entries => {
            const { isIntersecting } = entries[0];
            console.log('observer triggered', isIntersecting);
            if (isIntersecting) {
                unobserve();
                loadHtml();
            }
        }, {
            rootMargin: '100% 0% 100% 0%'
        });
        observer.observe(el);
        console.log('observing');
    };

    const init = () => {
        observe();
    };

    const destroy = () => {
        if (observer) {
            observer.disconnect();
        }
    };

    return {
        init,
        destroy
    };

};

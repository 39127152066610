import gsap from 'gsap';
import $ from '../core/Dom';

export default (el, props) => {

    const $el = $(el);
    const { cookieName, cookieDuration } = props;

    let hasConsented = window.vrsg.getCookie(cookieName) === '1';

    if (hasConsented) {
        return null;
    }

    const destroy = () => {
        $el.off('click');
    };

    const consent = () => {
        hasConsented = true;
        gsap.timeline({
            onComplete: () => {
                el.hidden = true;
            }
        })
            .to(el.firstElementChild, { y: 30, duration: 0.5, ease: 'Quad.easeIn' }, 0)
            .to(el, { opacity: 0, duration: 0.3 }, 0.05);
        window.vrsg.setCookie(cookieName, '1', cookieDuration);
        destroy();
    };

    const init = () => {
        $el.on('click', 'button', consent);
        el.hidden = false;
        gsap.timeline({ delay: 0.5 })
            .fromTo(el.firstElementChild, { y: 30 }, { y: 0, duration: 0.5, ease: 'Quad.easeOut' }, 0)
            .fromTo(el, { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0.05);
    };

    return {
        init,
        destroy
    };

};

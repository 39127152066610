import gsap from 'gsap';
import ResizeObserver from 'resize-observer-polyfill';

import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Components from '../core/Components';
import superagent from '../core/request';
import Viewport from '../core/Viewport';
import Config from '../core/Config';

import Revealer from '../lib/Revealer';
import FitText from '../lib/fittext';
import i18n from '../lib/i18n';

import { COMPONENT_INIT, LISTING_FILTERS_CHANGE } from '../lib/events';

/**
 * @param el
 * @param baseUrl
 * @returns {{init: init, destroy: destroy}}
 */
export default (el, { baseUrl }) => {

    const $el = $(el);
    const $filters = $el.find('[data-component="ListFilters"]').eq(0);
    const $announcer = $el.find('[data-announcer]').eq(0);
    const $header = $el.find('[data-header]').eq(0);
    const heading = $header.find('[data-heading]').get(0);
    const counter = $header.find('[data-counter]').get(0);
    const loadMoreBtn = $el.find('[data-loadmore-btn]').get(0);

    let currentFilter = $filters.find('[data-filter][data-pressed="true"]').data('filter') || null;

    let request;
    let currentPage;
    let totalPages;

    let resizeObserver;

    const announce = text => $announcer.text(text);

    const updateCounterFontSize = () => {
        if (!counter || !heading) {
            return;
        }
        const fittext = $(heading).find('.js-fitted').get(0);
        if (!fittext) {
            return;
        }
        const { fontSize } = fittext.firstElementChild.style;
        gsap.set(counter.firstElementChild, { fontSize });
    };

    const updateHeader = $newHeader => {
        if (!$newHeader.length) {
            return;
        }
        const $newHeading = $newHeader.find('[data-heading]').eq(0);
        const $newCounter = $newHeader.find('[data-counter]').eq(0);
        if (!(($newHeading.text().trim() !== $(heading).text().trim()) || (counter && $newCounter.length && $newCounter.text().trim() !== $(counter).text().trim()))) {
            return;
        }
        const headingContainer = $(heading).parent().get(0);
        const tween = gsap.timeline()
            .to(headingContainer, { opacity: 0, duration: 0.3 }, 'out')
            .to(heading, { y: 5, duration: 0.3, ease: 'Quad.easeIn' }, 'out');
        if (counter) {
            tween
                .to(counter, { opacity: 0, duration: 0.3 }, 'out')
                .to(counter, { y: 10, duration: 0.3, ease: 'Quad.easeIn' }, 'out');
        }
        tween
            .add(() => {
                // Set a fixed height to the header
                const height = $header.height();
                gsap.set($header.get(0), { height });
                // Change the heading text
                $(heading).html($newHeading.html()).removeClass('js-fitted');
                // Change the counter text
                if (counter && $newCounter.length) {
                    $(counter).html($newCounter.html());
                }
                // Re-fit the text
                FitText.update();
                updateCounterFontSize();
                gsap.set([headingContainer, heading, counter].filter(node => !!node), { clearProps: 'opacity,y' });
                Revealer.update();
            });
    };

    const maybeDisableLoadMore = () => {
        if (currentPage >= totalPages) {
            // Disable and (maybe) hide the loadmore button
            loadMoreBtn.setAttribute('aria-disabled', 'true');
            if (document.activeElement !== loadMoreBtn) {
                loadMoreBtn.hidden = true;
            }
        } else {
            loadMoreBtn.removeAttribute('aria-disabled');
            loadMoreBtn.hidden = false;
        }
    };

    const replaceList = $html => {
        const $list = $(el).find('[data-list]').eq(0);
        const $newList = $html.find('[data-list]').eq(0);
        gsap.set($list.get(0), { height: $list.height(), overflow: 'hidden' });
        gsap.timeline()
            .to($list.get(0), { opacity: 0, duration: 0.3 })
            .add(() => {
                Components.destroy($list.get(0));
                $list.html($newList.html());
                Components.init($list.get(0));
                maybeDisableLoadMore();
                Revealer.update();
                gsap.timeline()
                    .to($list.get(0), { height: 'auto', duration: 0.3 })
                    .set($list.get(0), { clearProps: 'height,overflow' });
            })
            .to($list.get(0), { opacity: 1, duration: 0.3 });
        const $newHeader = $html.find('[data-header]').eq(0);
        updateHeader($newHeader);
    };

    const appendToList = $html => {
        const $newList = $html.find('[data-list]').eq(0);
        const numNewItems = $newList.find('[role="listitem"]').length;
        const $listing = $(el).find('[data-list]').eq(0);
        $listing.append($newList.get(0).children);
        Components.init($listing.get(0));
        announce(i18n('Added {count} items to the list', { count: numNewItems }));
        maybeDisableLoadMore();
        Revealer.update();
    };

    const updateList = (append = false) => {
        if (request) {
            request.abort();
        }
        $el.addClass('js-is-loading');
        let url = `/${baseUrl}`;
        if (currentFilter) {
            url += `/${currentFilter.replace(':', '/')}`;
        }
        if (currentPage > 1) {
            const { pageTrigger } = Config.get();
            url += `/${pageTrigger}${currentPage}`;
        }
        request = superagent.get(url, { cache: true, ajax: true });
        request
            .then(({ status, text }) => {
                if (status !== 200 || !text) {
                    throw new Error();
                }
                const $html = $(`<div>${text}</div>`).find(`#${el.id}`).eq(0);
                // Update total and offset
                currentPage = $html.find('[data-current-page]').data('current-page') || 1;
                totalPages = $html.find('[data-total-pages]').data('total-pages') || 1;
                if (append) {
                    appendToList($html);
                } else {
                    replaceList($html);
                }
            })
            .catch(error => {
                console.error(error);
            })
            .then(() => {
                request = null;
                $el.removeClass('js-is-loading');
            });
    };

    const resetPagination = () => {
        currentPage = 1;
        totalPages = 1;
    };

    const loadMore = () => {
        if (request || currentPage >= totalPages) {
            return;
        }
        currentPage += 1;
        updateList(true);
    };

    const onFiltersChange = (key, data) => {
        const { id, filter } = data;
        if (id !== $filters.attr('id') || filter === currentFilter) {
            return;
        }
        currentFilter = filter;
        resetPagination();
        // If the top of the list is not in the viewport, scroll to it
        const { top } = el.getBoundingClientRect();
        if (top < 0) {
            const scrollTop = Viewport.scrollTop + top;
            gsap.to($('html,body').get(), { scrollTop, duration: 0.5, ease: 'Quad.easeOut' });
        }
        updateList();
    };

    const onLoadMoreBtnClick = e => {
        e.preventDefault();
        loadMoreBtn.focus();
        loadMore();
    };

    const onLoadMoreBtnKey = e => {
        loadMoreBtn.focus();
        const key = e.key || e.which || e.keyCode || null;
        const isSpace = [' ', 32].indexOf(key) > -1;
        const isEnter = ['Enter', 13].indexOf(key) > -1;
        if (!isSpace && !isEnter) {
            return;
        }
        if (e.type === 'keydown') {
            e.preventDefault();
            return;
        }
        loadMore();
    };

    const onHeadingResize = () => {
        updateCounterFontSize();
    };

    const onResize = () => {
        if ($header.length) {
            gsap.set($header.get(0), { clearProps: 'height' });
            updateCounterFontSize();
        }
    };

    const init = () => {
        currentPage = $el.find('[data-current-page]').data('current-page') || 1;
        totalPages = $el.find('[data-total-pages]').data('total-pages') || 1;
        Dispatch.on(LISTING_FILTERS_CHANGE, onFiltersChange);
        $(loadMoreBtn)
            .on('click', onLoadMoreBtnClick)
            .on('keydown keyup', onLoadMoreBtnKey);
        if (heading) {
            resizeObserver = new ResizeObserver(onHeadingResize);
            resizeObserver.observe(heading);
        }
        Viewport.on('resize', onResize);
        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        Dispatch.off(LISTING_FILTERS_CHANGE, onFiltersChange);
        $(loadMoreBtn).off('click keydown keyup');
    };

    return {
        init,
        destroy
    };

};

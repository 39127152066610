import gsap from 'gsap';

import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default (el, trigger) => {

    const $el = $(el);
    const $close = $el.find('[data-closebtn]');

    let activeElement;
    let isOpen = false;

    const position = () => {
        gsap.set(el, { clearProps: 'all' });
        const { width: viewportWidth } = $('#main').get(0).getBoundingClientRect();
        const { width, left } = el.getBoundingClientRect();
        let x = viewportWidth - (left + width);
        if (x > 0) {
            x = 0;
        }
        const offset = parseInt($el.css('padding-left').replace('px', ''), 10) * 0.5;
        if ((width + offset) < viewportWidth) {
            x -= offset;
        }
        gsap.set(el, { x, y: offset });
    };

    const hide = () => {
        isOpen = false;
        $close.off('click');
        if (activeElement && activeElement.closest('html')) {
            activeElement.focus();
        } else if (trigger) {
            trigger.focus();
        }
        if (trigger) {
            $(trigger).attr({ 'aria-expanded': 'false' });
        }
        activeElement = null;
        Viewport.off('resize', position);
        gsap.timeline({
            onComplete() {
                el.hidden = true;
            }
        })
            .to(el, { opacity: 0, duration: 0.3, ease: 'Cubic.easeOut' }, 0)
            .to(el, { scale: 0.98, duration: 0.3, transformOrigin: 'left top' }, 0);
    };

    const show = () => {
        gsap.killTweensOf(el);
        isOpen = true;
        activeElement = document.activeElement || null;
        el.hidden = false;
        position();
        $close.get(0).focus();
        $close.on('click', hide);
        if (trigger) {
            $(trigger).attr({ 'aria-expanded': 'true' });
        }
        Viewport.on('resize', position);
        gsap.timeline()
            .fromTo(el, { opacity: 0 }, { opacity: 1, duration: 0.3, ease: 'Cubic.easeOut' }, 0)
            .fromTo(el, { scale: 0.99 }, { scale: 1, duration: 0.3, transformOrigin: 'left top' }, 0);
    };

    const onBodyClick = e => {
        if (!isOpen) {
            return;
        }
        if (e.type === 'keydown') {
            const key = e.key || e.which || e.keyCode || null;
            if (['Escape', 27].indexOf(key) > -1) {
                hide();
                return;
            }
            const isSpace = [' ', 32].indexOf(key) > -1;
            const isEnter = ['Enter', 13].indexOf(key) > -1;
            if (!isSpace && !isEnter) {
                return;
            }
        }
        const { target } = e;
        if (target !== el && (!trigger || target !== trigger) && !el.contains(target)) {
            activeElement = document.activeElement || null;
            hide();
        }
    };

    const destroy = () => {
        $('body').off('click keydown focus', onBodyClick);
        hide();
    };

    $('body').on('click keydown focus', onBodyClick);

    return {
        show,
        destroy
    };

};

// Get the current time from the server
import Config from '../core/Config';
import superagent from '../core/request';
import Dispatch from '../core/Dispatch';

import { CURRENT_TIME_SET } from './events';

// Do an AJAX request to return the current time from the server
let time = Config.get('time');
let timeRequest;
export const getTime = () => {
    if (time) {
        // We already got the time
        Dispatch.emit(CURRENT_TIME_SET, time);
        return;
    }
    if (timeRequest) {
        // We're already fetching the time
        return;
    }
    const { timeEndpoint } = Config.get();
    if (!timeEndpoint) {
        // Oops we have can't get the time
        return;
    }
    // Get the time
    timeRequest = superagent.get(timeEndpoint);
    timeRequest
        .then(({ status, text }) => {
            if (status !== 200 || !text || !parseInt(text, 10)) {
                throw new Error();
            }
            time = parseInt(text, 10);
            Config.set('time', time);
            Dispatch.emit(CURRENT_TIME_SET, time);
        })
        .catch(error => {
            console.error(error);
        })
        .then(() => {
            timeRequest = null;
        });
};

const tzSupports = {};
export const supportsTz = ianaTz => {
    if (tzSupports.hasOwnProperty(ianaTz)) {
        return tzSupports[ianaTz];
    }
    try {
        new Intl.DateTimeFormat('en', { timeZone: ianaTz });
        tzSupports[ianaTz] = true;
    } catch (error) {
        tzSupports[ianaTz] = false;
    }
    return tzSupports[ianaTz];
};

/**
 *
 * @param node
 * @param eventName
 * @param eventOpts
 */
export const dispatchEvent = (node, eventName, eventOpts) => {
    node.dispatchEvent(new CustomEvent(eventName, {
        bubbles: true,
        cancelable: true,
        ...(eventOpts || {})
    }));
};

export const isLivePreview = () => document.documentElement.classList.contains('is-preview') && document.documentElement.classList.contains('is-iframe');

import gsap from 'gsap';
import shuffle from 'lodash/shuffle';
import Flickity from 'flickity';

import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';

import { COMPONENT_INIT } from '../lib/events';

export default el => {

    const $el = $(el);

    const { autoplay, delay, random } = $el.data();

    let $slides = $el.find('[data-slide]');
    let captions = null;

    const $slider = $slides.parent().eq(0);
    const images = $slides.find('[data-image]').get();
    const cursor = $el.find('[data-cursor]').get(0);

    const isTouch = () => !document.documentElement.classList.contains('using-mouse');

    let flkty;
    let captionClone;
    let selectedIndex = 0;
    let numSlides;
    let preloadTimer = null;
    let isDragging = false;
    let direction;

    const hideCursor = () => {
        cursor.hidden = true;
        requestAnimationFrame(() => {
            el.classList.remove('js-hide-cursor');
        });
    };

    const showCursor = dir => {
        if (isTouch() || isDragging) {
            hideCursor();
            return;
        }
        cursor.hidden = false;
        if (dir === 'next') {
            gsap.set(cursor, { scaleX: 1 });
        } else {
            gsap.set(cursor, { scaleX: -1 });
        }
        requestAnimationFrame(() => {
            el.classList.add('js-hide-cursor');
        });
    };

    const destroyFlickity = () => {
        if (!flkty) return;
        flkty.off('change');
        flkty.off('select');
        flkty.off('dragStart');
        flkty.off('dragEnd');
        flkty.destroy();
        flkty = null;
    };

    const updateCaption = () => {
        if (!captionClone) {
            return;
        }
        const $captionClone = $(captionClone);
        const heading = $captionClone.find('[data-heading]').get(0);
        const text = $captionClone.find('[data-text]').get(0);
        const link = $captionClone.find('a').get(0);
        const counter = $captionClone.find('[data-counter-current]').get(0);
        const delimiter = $captionClone.find('[data-counter-delimit]').get(0);
        const multiplier = direction === 'forward' ? 1 : -1;
        const tween = gsap.timeline({
            paused: true
        })
            .to([heading, text], { opacity: 0, duration: 0.3 }, 'out')
            .to(counter, { opacity: 0, y: -5 * multiplier, duration: 0.3 }, 'out')
            .add(() => {
                const $slide = $(flkty.selectedSlide.cells[0].element);
                $(heading).html($slide.find('[data-caption] [data-heading]').html());
                $(text).html($slide.find('[data-caption] [data-text]').html());
                $(counter).text(flkty.selectedIndex + 1);
                $(link).attr('href', $slide.find('a').attr('href'));
                $captionClone.find('.js-hover').each(node => {
                    $(node).removeClass('js-hover');
                    gsap.set(node, { clearProps: 'all' });
                });
            })
            .to([heading, text], {
                opacity: 1,
                duration: 0.5,
                ease: 'Cubic.easeOut',
                immediateRender: false
            }, 'in+=0.1')
            .fromTo(counter, { opacity: 0 }, {
                opacity: 1,
                duration: 0.3,
                ease: 'Cubic.easeOut',
                immediateRender: false
            }, 'in')
            .fromTo(counter, { y: 5 * multiplier }, {
                y: 0,
                duration: 0.3,
                ease: 'Quad.easeOut',
                immediateRender: false
            }, 'in');
        requestAnimationFrame(() => {
            gsap.timeline()
                .to(delimiter, {
                    rotate: 360 * multiplier,
                    ease: 'Power2.easeInOut',
                    duration: 0.65
                })
                .set(delimiter, { clearProps: 'all' });
            tween.play();
        });
    };

    const preloadNextSlide = () => {
        if (preloadTimer) {
            clearTimeout(preloadTimer);
            preloadTimer = null;
        }
        const nextSlide = $el.find('[data-slide]').get(selectedIndex + 1);
        if (!nextSlide) {
            return;
        }
        $(nextSlide).find('[data-image]').get(0).firstElementChild.hidden = false;
        $(nextSlide).find('.lazyload:not(.lazyloaded)').addClass('lazypreload');
    };

    const onSelect = () => {

        if (flkty) {
            const newIndex = flkty.selectedIndex;
            if (selectedIndex === 0) {
                // First image
                direction = newIndex === flkty.slides.length - 1 ? 'back' : 'forward';
            } else if (selectedIndex === flkty.slides.length - 1) {
                // Last image
                direction = newIndex === 0 ? 'forward' : 'back';
            } else {
                // Somewhere in the middle
                direction = newIndex > selectedIndex ? 'forward' : 'back';
            }
            selectedIndex = newIndex;
            const currentSlide = $el.find('[data-slide]').get(selectedIndex);
            $(currentSlide).find('[data-image]').get(0).firstElementChild.hidden = false;
        }

        if (numSlides > 1) {
            const preloadDelay = ((delay || 3) - 1) * 1000;
            preloadTimer = setTimeout(() => {
                preloadNextSlide();
            }, preloadDelay);
        }

    };

    const onChange = () => {
        updateCaption();
    };

    const initFlickity = () => {

        flkty = new Flickity($slider.get(0), {
            pageDots: false,
            adaptiveHeight: false,
            prevNextButtons: false,
            setGallerySize: false,
            groupCells: false,
            resize: false,
            wrapAround: true,
            percentPosition: true,
            accessibility: true,
            dragThreshold: 10,
            // selectedAttraction: 0.025,
            // friction: 0.3,
            lazyLoad: false,
            autoPlay: !!delay && !!autoplay ? (delay * 1000) : false,
            on: {
                change: onChange,
                select: onSelect,
                dragStart: () => {
                    isDragging = true;
                    requestAnimationFrame(() => {
                        hideCursor();
                        el.classList.add('is-dragging');
                    });
                },
                dragEnd: () => {
                    requestAnimationFrame(() => {
                        isDragging = false;
                        el.classList.remove('is-dragging');
                    });
                }
            }
        });
    };

    const next = () => {
        if (!flkty) return;
        flkty.stopPlayer();
        flkty.next();
    };

    const setCaptionHeight = () => {
        const nodes = captions.concat(captionClone);
        gsap.set(nodes, { clearProps: 'height' });
        gsap.set(nodes, { height: captions[0].getBoundingClientRect().height });
    };

    const onResize = () => {
        setCaptionHeight();
    };

    const onMouseMove = e => {
        if (isTouch() || !flkty) {
            hideCursor();
            return;
        }

        const sliderHeight = images[0].getBoundingClientRect().height;
        const { left, top, width } = flkty.element.getBoundingClientRect();
        const mouseX = e.clientX - left;
        const mouseY = e.clientY - top;

        if (mouseY < 0 || mouseY > sliderHeight) {
            hideCursor();
        } else if (mouseX >= width * 0.9) {
            showCursor('next');
        } else if (mouseX > 0 && mouseX <= (width * 0.1)) {
            showCursor('prev');
        } else {
            hideCursor();
        }

        gsap.set(cursor, { x: mouseX - 12, y: mouseY + 18 });
    };

    const onMouseLeave = () => {
        hideCursor();
    };

    const onSliderClick = e => {

        if (!flkty || isTouch()) {
            return;
        }

        if (isDragging) {
            e.preventDefault();
            return;
        }

        const { left, width } = flkty.element.getBoundingClientRect();
        const mouseX = e.clientX - left;

        const isNext = mouseX >= width * 0.8;
        const isPrev = !isNext && mouseX <= (width * 0.2);

        if (!isNext && !isPrev) {
            return;
        }

        e.preventDefault();

        if (isNext) {
            flkty.next();
        } else {
            flkty.previous();
        }
    };

    const init = () => {

        // Randomize slides?
        if (random && $slides.length > 1) {
            const $slidesContainer = $slides.parent().eq(0);
            const slidesArray = shuffle($slides.get());
            $slidesContainer.append(slidesArray);
            $slides = $slidesContainer.find('[data-slide]');
        }

        captions = $slides.find('[data-caption]').get();

        numSlides = $slides.length;

        onResize();

        initFlickity();

        $(flkty.element)
            .on('mousemove', onMouseMove)
            .on('mouseleave', onMouseLeave)
            .on('click', onSliderClick);

        // Create a cloned caption to display
        const $captionClone = $(captions[0]).clone().eq(0);
        captionClone = $captionClone.get(0);
        $captionClone.attr({ 'aria-live': 'polite' });
        $captionClone.find('.js-hover').each(node => {
            $(node).removeClass('js-hover');
            gsap.set(node, { clearProps: 'all' });
        });

        const link = document.createElement('a');
        const $text = $captionClone.find('[data-heading]').parent();

        $(link).html($text.html()).attr({
            class: $text.attr('class'),
            href: $(images[0]).parent('a').attr('href')
        });

        const textNode = $text.get(0);
        textNode.parentElement.replaceChild(link, textNode);
        gsap.set(captionClone, {
            position: 'absolute',
            width: '100%',
            left: 0,
            bottom: 0,
            zIndex: 2
        });

        $(flkty.element).parent().append(captionClone);

        // Add counter to the caption clone
        $(captionClone.firstElementChild).append(`
                <button class="font-bold absolute top-0 right-0 js u-hit whitespace-no-wrap pointer-events-auto u-hit" data-counter aria-label="Click to view next slide">
                    <span class="inline-block" data-counter-current>1</span> <span class="inline-block px-4" data-counter-delimit>/</span> <span class="inline-block">${flkty.slides.length}</span>
                </button>
            `);

        // Hide real captions
        gsap.set(captions, { autoAlpha: 0, pointerEvents: 'none' });
        $el.on('click', '[data-counter]', next);

        Viewport.on('resize', onResize);
        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        if (flkty) {
            $(flkty.element).off('mousemove mouseleave click');
            destroyFlickity();
            $el.off('click');
            $el.find('[data-slide] a').off('mouseenter mousemove mouseleave');
        }
        if (preloadTimer) {
            clearTimeout(preloadTimer);
        }
    };

    return {
        init,
        destroy
    };

};

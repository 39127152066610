import gsap from 'gsap';
import Config from '../core/Config';
import $ from '../core/Dom';
import superagent from '../core/request';

import LoginModal from './LoginModal';

export default () => {

    const download = url => {
        window.location.href = `${url}?download=1`;
    };

    const onClick = e => {
        e.preventDefault();
        const $target = $(e.triggerTarget);
        const url = $target.attr('href');
        const label = $target.find('[data-label]').get(0);
        const spinner = $target.find('[data-spinner]').get(0);
        if (label && spinner) {
            spinner.hidden = false;
            gsap.set(label, { visibility: 'hidden' });
        }
        superagent.get(url)
            .accept('application/json')
            .ok(res => res.status < 500)
            .then(res => {
                const { status } = res;
                if (status === 200) {
                    // Trigger the download
                    download(url);
                    return;
                }
                if (status === 403) {
                    const { html } = res.body; // The endpoint returns the HTML for the login form if not authenticated
                    const modal = LoginModal(html, {
                        onSuccess: ({ target: form }) => {
                            if (!$(form).parent('[data-form="login"]').length) {
                                return;
                            }
                            modal.close();
                            download(url);
                        }
                    });
                    modal.show();
                    return;
                }
                throw new Error();
            })
            .catch(error => {
                console.log({ error });
            })
            .then(() => {
                if (label && spinner) {
                    spinner.hidden = true;
                    gsap.set(label, { clearProps: 'visibility' });
                }
            });
    };

    const init = () => {
        const { siteUrl } = Config.get();
        $('body').on('click', `a[href^="${siteUrl}/brand/downloads/"]`, onClick);
    };

    const destroy = () => {
        $('body').off('click', onClick);
    };

    return {
        init,
        destroy
    };

};

import Modal from './Modal';

export default (html, opts = {}) => ({
    onSuccess() {},
    onDestroy() {
        this.$el.off('success', this.onSuccess);
        this.$el = null;
        this.modal = null;
    },
    onFormBtnClick(e) {
        const { triggerTarget: target } = e;
        const { formbtn: formId } = target.dataset;
        this.$el.find(`[data-form]:not([data-form="${formId}"])`).each(form => { form.hidden = true; });
        this.$el.find(`[data-form="${formId}"]`).get(0).hidden = false;
    },
    show() {
        this.onSuccess = this.onSuccess.bind(this);
        this.onDestroy = this.onDestroy.bind(this);
        this.modal = Modal(html, {
            afterDestroy: this.onDestroy
        });
        this.modal.show();
        this.$el = this.modal.$el;
        this.$el.on('success', this.onSuccess);
        this.onFormBtnClick = this.onFormBtnClick.bind(this);
        this.$el.on('click', '[data-formbtn]', this.onFormBtnClick);
    },
    close() {
        this.modal.close();
    },
    ...opts
});

/*
 *   Lazysizes config
 *
 */

window.lazySizesConfig = {
    init: false
};

// Remove bg for image containers when the image unveils (to prevent the color from showing for transparent pngs)
document.addEventListener('lazyloaded', e => {
    const { target } = e;
    const container = target.closest('#main [class*="bg-gray-light"]');
    if (!container) {
        return;
    }
    requestAnimationFrame(() => {
        const classes = Array.from(container.classList);
        classes.forEach(className => {
            if (className.indexOf('bg-gray-light') === -1) {
                return;
            }
            if (container.getAttribute('class').indexOf('transition') === -1) {
                container.classList.add('transition-colors');
                container.classList.add('duration-300');
            }
            container.classList.remove(className);
        });
    });
});

require('lazysizes/plugins/parent-fit/ls.parent-fit');

if (!window.HTMLPictureElement || document.msElementsFromPoint) {
    require('lazysizes/plugins/respimg/ls.respimg');
}

const lazySizes = require('lazysizes');

export default {
    init: () => lazySizes.init()
};

import gsap from 'gsap';
import debounce from 'lodash/debounce';

import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';

import { COMPONENT_INIT, LISTING_FILTERS_CHANGE } from '../lib/events';

export default (el, { filterCookieName }) => {

    const { id } = el;

    console.log({ filterCookieName });

    const $el = $(el);
    const filtersWrap = $el.find('[data-filters]').get(0);
    const resetFilterBtn = $el.find('[data-filter-reset]').get(0);
    const closeFiltersBtn = $el.find('[data-filters-close]').get(0);
    const searchForm = $el.find('#search form').get(0);
    const searchBtn = $el.find('button[aria-controls="search"]').get(0);

    let expandedFilterGroup = null;
    let currentFilter = $el.find('[data-filter][data-pressed="true"]').data('filter') || null;

    let searchQuery = null;
    let isSearchOpen = false;
    let focusedElement = null;
    let searchTl = null;

    let prevScrollTop = -1;

    const setFilterCookie = () => {
        if (!filterCookieName) {
            return;
        }
        const value = currentFilter || '';
        window.vrsg.setCookie(filterCookieName, value);
        console.log('filter cookie set', window.vrsg.getCookie(filterCookieName));
    };

    const updateUrl = () => {
        if (!$el.find('[data-filter][data-href]').length) {
            return;
        }
        const btn = currentFilter ? $el.find(`[data-filter="${currentFilter}"]`).get(0) : resetFilterBtn;
        const url = btn.dataset.href;
        if (!url || url === window.location.href) {
            return;
        }
        window.history.pushState({ currentFilter, searchQuery }, document.title, url);
    };

    const emitChange = (pushState = true) => {
        if (pushState) {
            updateUrl();
        }
        Dispatch.emit(LISTING_FILTERS_CHANGE, {
            id,
            searchQuery,
            filter: currentFilter
        });
    };

    /**
     * Resets the focus to the focusedElement
     */
    const resetFocus = () => {
        if (!focusedElement) {
            return;
        }
        focusedElement.focus();
        focusedElement = null;
    };

    /**
     * Set active filter button
     */
    const setActiveFilterButton = () => {
        if (currentFilter) {
            $el.find(`[data-filter="${currentFilter}"]`).attr('aria-pressed', 'true');
            $el.find(`[data-filter]:not([data-filter="${currentFilter}"])`).attr('aria-pressed', 'false');
        } else {
            $el.find('[data-filter]').attr('aria-pressed', 'false');
        }
    };

    /**
     * Set the active filter group button
     * @param btn
     */
    const setActiveFilterGroupButton = btn => {
        if (btn) {
            $el.find('[data-filter-group],[data-filter-reset]').addClass('inactive');
            $(btn).removeClass('inactive');
            return;
        }
        if (!expandedFilterGroup && !currentFilter && !searchQuery) {
            resetFilterBtn.classList.remove('inactive');
            resetFilterBtn.setAttribute('aria-pressed', 'true');
        } else {
            resetFilterBtn.classList.add('inactive');
            resetFilterBtn.setAttribute('aria-pressed', 'false');
        }
        $el.find('[data-filter-group]').addClass('inactive');
        if (expandedFilterGroup) {
            expandedFilterGroup.classList.remove('inactive');
        } else if (currentFilter) {
            const group = currentFilter.split(':')[0];
            $el.find(`[data-filter-group="${group}"]`).get(0).classList.remove('inactive');
        }
    };

    const getExpandedFilterGroupHeight = panel => {
        gsap.set(panel, { clearProps: 'height' });
        const { height, top } = $el.get(0).getBoundingClientRect();
        return Math.max((Viewport.height + 100) - (top + height), $(panel).height());
    };

    const setExpandedFilterGroupHeight = panel => {
        const height = getExpandedFilterGroupHeight(panel);
        gsap.killTweensOf([panel, panel.firstElementChild]);
        gsap.set([panel, panel.firstElementChild], { clearProps: 'all' });
        gsap.set(panel, { height });
    };

    const closeFilterGroup = group => {
        group.setAttribute('aria-expanded', 'false');
        group.classList.add('inactive');
        const panel = group.nextElementSibling;
        gsap.timeline({
            onComplete() {
                if (group !== expandedFilterGroup) {
                    gsap.set(panel, { clearProps: 'zIndex' });
                    gsap.set(panel.firstElementChild, { clearProps: 'y' });
                    panel.hidden = true;
                }
                if (!expandedFilterGroup) {
                    gsap.set(filtersWrap, { x: 0, y: 0 });
                }
            }
        })
            .to(panel, { height: 0, duration: 0.5, ease: 'Cubic.easeInOut' }, 0)
            .to(panel.firstElementChild, { opacity: 0, duration: 0.3, ease: 'none' }, 0)
            .to(panel.firstElementChild, { y: 30, duration: 0.5, ease: 'Quad.easeIn' }, 0);
    };

    const openFilterGroup = group => {
        gsap.killTweensOf(filtersWrap);
        gsap.set(filtersWrap, { clearProps: 'all' });
        group.setAttribute('aria-expanded', 'true');
        const panel = group.nextElementSibling;
        panel.hidden = false;
        focusedElement = document.activeElement || null;
        const firstFilter = $(panel).find('[role="menuitem"]').get(0);
        if (firstFilter) {
            firstFilter.focus();
        }
        const toHeight = getExpandedFilterGroupHeight(panel);
        let fromHeight;
        if (expandedFilterGroup && expandedFilterGroup !== group) {
            gsap.set(expandedFilterGroup.nextElementSibling, { zIndex: 10 });
            closeFilterGroup(expandedFilterGroup);
            fromHeight = toHeight;
        } else {
            fromHeight = gsap.isTweening(panel) ? $(panel).height() : 0;
        }
        const tl = gsap.timeline({ paused: true })
            .fromTo(panel, { height: fromHeight }, { height: toHeight, duration: 0.3, ease: 'Quad.easeIn' }, 0)
            .fromTo(panel.firstElementChild, { opacity: 0 }, { opacity: 1, duration: 0.5, ease: 'Cubic.easeIn' }, 0)
            .fromTo(panel.firstElementChild, { y: 100 }, { y: 0, duration: 1, ease: 'Quint.easeOut' }, 0);
        requestAnimationFrame(() => {
            tl.play();
        });
    };

    const setExpandedFilterGroup = group => {
        if (group) {
            if (group.getAttribute('aria-expanded') === 'true') {
                closeFilterGroup(group);
                expandedFilterGroup = null;
            } else {
                openFilterGroup(group);
                expandedFilterGroup = group;
            }
        } else if (expandedFilterGroup) {
            closeFilterGroup(expandedFilterGroup);
            expandedFilterGroup = null;
        }
        setActiveFilterGroupButton();
        if (expandedFilterGroup && (closeFiltersBtn && closeFiltersBtn.hidden)) {
            // Something is open, show the close button
            closeFiltersBtn.hidden = false;
            const tl = gsap.timeline({
                onComplete() {
                    if (!searchBtn) {
                        return;
                    }
                    searchBtn.hidden = true;
                }
            });
            if (searchBtn) {
                tl
                    .fromTo(closeFiltersBtn, { opacity: 0, xPercent: 100 }, { opacity: 1, xPercent: 0, duration: 0.3, ease: 'Quad.easeInOut' }, 0)
                    .to(searchBtn, { xPercent: -100, scale: 0, opacity: 0, duration: 0.3, ease: 'Quad.easeInOut' }, 0);
            } else {
                tl.fromTo(closeFiltersBtn, { opacity: 0, scale: 0.5 }, { opacity: 1, scale: 1, duration: 0.3, ease: 'Quad.easeInOut' }, 0);
            }
        } else if (!expandedFilterGroup && (closeFiltersBtn && !closeFiltersBtn.hidden)) {
            // Nothing is open, hide the close button
            const tl = gsap.timeline({
                onStart() {
                    if (!searchBtn) {
                        return;
                    }
                    searchBtn.hidden = false;
                },
                onComplete() {
                    closeFiltersBtn.hidden = true;
                }
            });
            if (searchBtn) {
                tl
                    .to(closeFiltersBtn, { opacity: 0, xPercent: 100, duration: 0.3, ease: 'Quad.easeInOut' }, 0)
                    .to(searchBtn, { opacity: 1, xPercent: 0, scale: 1, duration: 0.3, ease: 'Quad.easeInOut' }, 0)
                    .set(searchBtn, { clearProps: 'all' });
            } else {
                tl.to(closeFiltersBtn, { opacity: 0, scale: 0.5, duration: 0.3, ease: 'Quad.easeInOut' }, 0);
            }
        }
    };

    const createSearchTimeline = () => {
        const searchPanel = searchBtn.nextElementSibling;
        const bgColor = $(searchPanel).css('background-color');
        const toggleIcon = $(searchBtn).find('[data-icon]').get(0);
        const toggleClose = $(searchBtn).find('[data-close]').get(0);
        const time = searchTl ? searchTl.time() : 0;
        const killSearchTimeline = () => {
            searchTl.kill();
            searchTl = null;
            gsap.set([searchPanel, searchBtn, searchForm, toggleIcon, toggleClose], { clearProps: 'all' });
        };
        if (searchTl) {
            killSearchTimeline();
        }
        searchTl = gsap.timeline({
            paused: true,
            onReverseComplete() {
                searchPanel.hidden = true;
                killSearchTimeline();
            }
        })
            .to(toggleIcon, { opacity: 0, duration: 0.001 }, 0)
            .fromTo(toggleClose, { opacity: 0, xPercent: 50 }, { opacity: 1, xPercent: 0, duration: 0.3, ease: 'Quint.easeOut', transformOrigin: 'right center' }, 0.2)
            .fromTo(searchPanel, { backgroundColor: 'transparent' }, { backgroundColor: bgColor, duration: 0.3 }, 0)
            .fromTo(searchForm, { xPercent: 100, x: -($(searchBtn).width()) }, { xPercent: 0, x: 0, duration: 0.6, ease: 'Quint.easeInOut' }, 0);
        searchTl.pause(time);
    };

    const closeSearch = () => {
        if (!isSearchOpen) {
            return;
        }
        isSearchOpen = false;
        searchBtn.setAttribute('aria-expanded', 'false');
        if (!searchTl) {
            createSearchTimeline();
        }
        searchTl.reverse();
        setActiveFilterGroupButton();
    };

    const openSearch = () => {
        if (isSearchOpen) {
            return;
        }
        isSearchOpen = true;
        setExpandedFilterGroup(null);
        const searchPanel = searchBtn.nextElementSibling;
        const searchInput = $(searchBtn.nextElementSibling).find('input[type="search"]').get(0);
        searchPanel.hidden = false;
        searchBtn.setAttribute('aria-expanded', 'true');
        focusedElement = document.activeElement || null;
        searchInput.focus();
        searchInput.select();
        if (!searchTl) {
            createSearchTimeline();
        }
        searchTl.play();
    };

    const resetSearch = () => {
        searchQuery = null;
        if (searchForm) {
            $(searchForm).find('input[type="search"]').val('').removeClass('js-has-value');
        }
        setActiveFilterGroupButton();
        setActiveFilterButton();
    };

    const resetFilter = () => {
        currentFilter = null;
        setActiveFilterGroupButton();
        setActiveFilterButton();
        setFilterCookie();
    };

    const setFilter = (filter, pushState = true) => {
        if (filter === currentFilter) {
            return;
        }
        currentFilter = filter;
        resetSearch();
        setActiveFilterGroupButton();
        setActiveFilterButton();
        emitChange(pushState);
        setFilterCookie();
    };

    const setSearchQuery = query => {
        let newSearchQuery = (query || '').trim();
        newSearchQuery = newSearchQuery.length > 2 ? newSearchQuery : null;
        if (newSearchQuery === searchQuery) {
            return;
        }
        searchQuery = newSearchQuery;
        if (searchQuery) {
            $(searchForm).find('input[type="search"]').addClass('js-has-value');
        } else {
            $(searchForm).find('input').removeClass('js-has-value');
        }
        createSearchTimeline();
        resetFilter();
        setActiveFilterGroupButton();
        setActiveFilterButton();
        emitChange();
    };

    const closeEverything = () => {
        if (expandedFilterGroup) {
            setExpandedFilterGroup(null);
        }
        if (isSearchOpen && !searchQuery) {
            resetSearch();
            closeSearch();
        }
        if (!expandedFilterGroup && !isSearchOpen) {
            resetFocus();
        }
    };

    /**
     * Event handlers
     *
     */
    const onFilterGroupBtnClick = e => {
        const { triggerTarget: btn } = e;
        closeSearch();
        setExpandedFilterGroup(btn);
    };

    const onFilterGroupBtnKeyDown = e => {
        if (e.key === 'ArrowDown' || (e.which || e.keyCode || null) === 40) {
            const { triggerTarget: btn } = e;
            e.preventDefault();
            if (expandedFilterGroup !== btn) {
                setExpandedFilterGroup(btn);
            } else {
                const panel = btn.nextElementSibling;
                $(panel).find('[data-filter]').get(0).focus();
            }
        }
    };

    const onFilterClick = e => {
        e.preventDefault();
        const { triggerTarget: btn } = e;
        if (btn.getAttribute('aria-disabled') === 'true') {
            return;
        }
        gsap.set(btn, { pointerEvents: 'none' });
        const { filter } = btn.dataset;
        if (filter === currentFilter) {
            setFilter(null);
        } else {
            setFilter(filter);
        }
        expandedFilterGroup.focus();
        setExpandedFilterGroup(null);
        setTimeout(() => {
            gsap.set(btn, { pointerEvents: '' });
        }, 300);
    };

    const onFilterKeyUp = e => {
        const key = e.key || e.keyCode || e.which || null;
        if ([' ', 32].indexOf(key) > -1) { // Space key
            onFilterClick(e);
        }
    };

    const onFilterKeyDown = e => {
        const key = e.key || e.keyCode || e.which || null;
        const isSpace = [' ', 32].indexOf(key) > -1;
        if (isSpace) {
            e.preventDefault();
            return;
        }
        const isNext = ['ArrowDown', 40].indexOf(key) > -1 || ['ArrowRight', 39].indexOf(key) > -1;
        const isPrev = ['ArrowUp', 38].indexOf(key) > -1 || ['ArrowLeft', 37].indexOf(key) > -1;
        if (!isNext && !isPrev) {
            return;
        }
        e.preventDefault();
        const { triggerTarget: btn } = e;
        const filterSiblings = $(btn).parent('[role="menu"]').find('[data-filter]').get();
        const index = $(btn).parent('[role="menu"]').find('[data-filter]').index(btn);
        let nextFilter;
        if (isNext && index < filterSiblings.length - 1) {
            nextFilter = $(filterSiblings).get(index + 1);
        } else if (isNext && index >= filterSiblings.length - 1) {
            nextFilter = $(filterSiblings).get(0);
        } else if (isPrev && index > 0) {
            nextFilter = $(filterSiblings).get(index - 1);
        } else {
            nextFilter = $(filterSiblings).get(filterSiblings.length - 1);
        }
        nextFilter.focus();
    };

    const onFilterResetClick = e => {
        e.preventDefault();
        focusedElement = resetFilterBtn;
        closeEverything();
        console.log({ searchQuery, currentFilter });
        if (!searchQuery && !currentFilter) {
            return;
        }
        resetSearch();
        resetFilter();
        emitChange(true);
    };

    const onFiltersCloseBtnClick = () => {
        if (!expandedFilterGroup) {
            return;
        }
        focusedElement = expandedFilterGroup;
        setExpandedFilterGroup(null);
        setActiveFilterGroupButton();
        focusedElement.focus();
    };

    const onFilterResetKeyUp = e => {
        const key = e.key || e.keyCode || e.which || null;
        if ([' ', 32].indexOf(key) > -1) { // Space key
            onFilterResetClick(e);
        }
    };

    const onFilterResetKeyDown = e => {
        const key = e.key || e.keyCode || e.which || null;
        const isSpace = [' ', 32].indexOf(key) > -1;
        if (isSpace) {
            e.preventDefault();
        }
    };

    const onSearchBtnClick = () => {
        if (!isSearchOpen) {
            openSearch();
            return;
        }
        resetSearch();
        closeSearch();
        setExpandedFilterGroup();
        emitChange();
    };

    const onBodyClick = e => {
        if (!expandedFilterGroup && !isSearchOpen) {
            return;
        }
        const { target } = e;
        if ($el.contains(target) || target === $el.get(0)) {
            return;
        }
        focusedElement = target;
        closeEverything();
    };

    const onBodyKeyUp = e => {
        if (!expandedFilterGroup && !isSearchOpen) {
            return;
        }
        if (e.key === 'Escape' || (e.which || e.keyCode || null) === 27) {
            closeEverything();
        }
    };

    const onScroll = () => {
        const { scrollTop } = Viewport;
        if (!!prevScrollTop && Math.abs(prevScrollTop - scrollTop) < 5) {
            return;
        }
        const direction = scrollTop > prevScrollTop ? 'down' : 'up';
        if (expandedFilterGroup) {
            const panel = expandedFilterGroup.nextElementSibling;
            setExpandedFilterGroupHeight(panel);
            const { top: panelTop } = panel.getBoundingClientRect();
            if (direction === 'down' && panelTop < 0) {
                // Is the bottom of the inner panel within the viewport?
                const { top: innerPanelTop, height: innerPanelHeight } = panel.firstElementChild.getBoundingClientRect();
                if ((Viewport.height - innerPanelTop) > (innerPanelHeight + 350)) {
                    setExpandedFilterGroup(null);
                }
            }
        } else if (isSearchOpen && !Viewport.visible(searchForm) && !searchQuery) {
            closeSearch();
        }
        prevScrollTop = scrollTop;
    };

    const onResize = () => {
        if (expandedFilterGroup) {
            const panel = expandedFilterGroup.nextElementSibling;
            setExpandedFilterGroupHeight(panel);
        }
    };

    const onSearchSubmit = e => {
        e.preventDefault();
        const query = $(searchForm).find('input[type="search"]').val();
        setSearchQuery(query);
    };

    const searchInputHandler = e => {
        const key = e.key || e.which || e.keyCode || null;
        if (['Escape', 27].indexOf(key) > -1) {
            return;
        }
        const query = $(searchForm).find('input[type="search"]').val();
        setSearchQuery(query);
    };

    const onSearchKeyUp = debounce(searchInputHandler, 150, { leading: false, trailing: true });

    const onSearchKeyDown = e => {
        const key = e.key || e.which || e.keyCode || null;
        if (['Escape', 27].indexOf(key) > -1) {
            e.preventDefault();
        }
    };

    const onPopState = e => {
        const { state } = e;
        closeEverything();
        const { currentFilter: newFilter } = state || {};
        setFilter(newFilter, false);
    };

    const init = () => {
        $el
            .on('click', '[data-filter-group', onFilterGroupBtnClick)
            .on('keydown', '[data-filter-group]', onFilterGroupBtnKeyDown)
            .on('click', '[data-filters-close]', onFiltersCloseBtnClick)
            .on('click', '[data-filter]', onFilterClick)
            .on('keydown', '[data-filter]', onFilterKeyDown)
            .on('keyup', '[data-filter]', onFilterKeyUp)
            .on('click', '[data-filter-reset]', onFilterResetClick)
            .on('keydown', '[data-filter-reset]', onFilterResetKeyDown)
            .on('keyup', '[data-filter-reset]', onFilterResetKeyUp)
            .on('submit', '#search form', onSearchSubmit)
            .on('keydown', '#search input[type="search"]', onSearchKeyDown)
            .on('keyup', '#search input[type="search"]', onSearchKeyUp)
            .on('click', '[data-searchbtn]', onSearchBtnClick);

        $('body')
            .on('click', onBodyClick)
            .on('keyup', onBodyKeyUp)
            .on('focusin', onBodyClick);

        Viewport.on('scroll', onScroll);
        Viewport.on('resize', onResize);

        $el.find('[data-pressed]').each(node => {
            node.setAttribute('aria-pressed', node.dataset.pressed);
            node.setAttribute('data-href', node.href);
            node.removeAttribute('href');
        });

        window.addEventListener('popstate', onPopState);

        setFilterCookie();

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        $el.off('click keydown keyup submit');
        $el.off('click keydown keyup');
        $('body')
            .off('click', onBodyClick)
            .off('keyup', onBodyKeyUp)
            .off('focusin', onBodyClick);
        Viewport.off('scroll', onScroll);
        Viewport.off('resize', onResize);
        window.removeEventListener('popstate', onPopState);
    };

    return {
        init,
        destroy
    };

};

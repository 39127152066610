import gsap from 'gsap';
import $ from '../core/Dom';

export default el => {

    const $el = $(el);
    const container = $el.parent().get(0);

    const offsetX = 0;
    const offsetY = 5;

    let isVisible = false;

    const isTouch = () => !document.documentElement.classList.contains('using-mouse');

    const hide = () => {
        if (!isVisible) return;
        isVisible = false;
        gsap.set(container, { clearProps: 'cursor' });
        gsap.set(el, { clearProps: 'visibility' });
    };

    const show = () => {
        if (isVisible || isTouch()) return;
        isVisible = true;
        gsap.set(container, { cursor: 'none' });
        gsap.set(el, { visibility: 'visible' });
    };

    const trackCursor = e => {
        if (e.target.hasAttribute('data-cursorbox-block')) {
            hide();
            return;
        }
        show();
        const { left, top } = container.getBoundingClientRect();
        gsap.set(el, {
            x: e.clientX - left - offsetX,
            y: e.clientY - top - offsetY
        });
    };

    const init = () => {
        $(container)
            .off('mouseenter', show)
            .on('mousemove', trackCursor)
            .on('mouseout', hide)
            .on('mouseleave', hide);
    };

    const destroy = () => {
        hide();
        $(container)
            .off('mouseenter', show)
            .off('mousemove', trackCursor)
            .off('mouseout', hide)
            .off('mouseleave', hide);
    };

    return {
        init,
        destroy
    };

};

import gsap from 'gsap';
import throttle from 'lodash/throttle';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';

const defaultStaggers = {
    x: 100,
    y: 100,
    chars: 100,
    default: 50
};

const defaultDurations = {
    x: 1,
    y: 1,
    default: 0.35
};

const tweens = new WeakMap();
const timeouts = new WeakMap();

let currentScrollTop = -1;
let direction = 'down';
let observer;
let intersecting = [];
let nodes = [];

const getTween = el => {

    let tween = tweens.get(el);

    if (tween) {
        return tween;
    }

    if (tween === false) {
        return null;
    }

    const type = el.dataset.reveal || null;

    el.setAttribute('data-revealed', true);

    if (type === 'show') {
        // No animation, the item should just be unhidden after the page loads
        tweens.set(el, false);
        return null;
    }

    if (el.hasAttribute('data-reveal-fold') && !Viewport.visible(el)) {
        // This animation should only be created if the element is visible/above the fold
        tweens.set(el, false);
        return null;
    }

    let duration = el.dataset.revealDuration;
    if (duration === undefined || !duration.length) {
        duration = defaultDurations[type] || defaultDurations.default || 1;
    }

    duration = parseFloat(duration);

    tween = gsap.timeline({
        paused: true
    });

    if (type === 'y') {

        const y = parseInt(el.dataset.revealY || 0, 10) || 150;
        tween
            .fromTo(el, {
                y
            }, {
                y: 0,
                ease: 'Quint.easeOut',
                duration
            }, 0)
            .fromTo(el, {
                opacity: 0
            }, {
                opacity: 1,
                duration: duration * 0.5,
                ease: 'Sine.easeOut'
            }, 0);

    } else if (type === 'chars') {

        const chars = $(el)
            .find('[data-char]')
            .get();

        if (!chars.length) {
            return null;
        }

        const stagger = 0.01;

        tween
            .fromTo(chars, {
                yPercent: 50
            }, {
                yPercent: 0,
                duration: 0.75,
                transformOrigin: 'center bottom',
                ease: 'Cubic.easeOut',
                stagger
            }, 0)
            .fromTo(chars, {
                opacity: 0
            }, {
                opacity: 1,
                duration: 0.5,
                ease: 'Cubic.easeOut',
                stagger
            }, 0);

    } else {
        // Default is fade
        tween
            .fromTo(el, { opacity: 0 }, {
                opacity: 1,
                duration,
                ease: 'Quad.easeIn'
            });
    }

    tweens.set(el, tween);

    return tween;
};

const onScroll = () => {
    const { scrollTop } = Viewport;
    direction = scrollTop >= currentScrollTop ? 'down' : 'up';
    currentScrollTop = scrollTop;
};

const trackNodes = () => {
    $('[data-reveal]:not([data-revealed])')
        .each(node => {
            if (!node.offsetParent) {
                return;
            }
            observer.observe(node);
            nodes.push(node);
        });
};

const scrollHandler = throttle(onScroll, 10);

const onObserve = entries => {

    entries.forEach(entry => {

        const {
            target,
            intersectionRatio
        } = entry;

        let { isIntersecting } = entry;

        // Get tween
        const tween = getTween(target);

        if (!tween) {
            return;
        }

        let timeout = timeouts.get(target);
        if (timeout) {
            clearTimeout(timeout);
            timeouts.delete(target);
        }

        let threshold = target.dataset.revealThreshold;
        if (threshold !== undefined) {
            threshold = parseFloat(threshold);
        }

        if (threshold && intersectionRatio < threshold) {
            isIntersecting = false;
        }

        const alwaysReveal = target.hasAttribute('data-reveal-always');

        if (isIntersecting) {
            intersecting.push(target);
            // Easiest way I could think of to sort the array of intersecting elements according to their chronological position in the DOM (which is a good idea)
            intersecting = nodes.filter(node => intersecting.indexOf(node) > -1);
        } else {
            intersecting = intersecting.filter(node => node !== target);
        }

        const { top } = target.getBoundingClientRect();

        if (!isIntersecting && direction === 'up' && top >= Viewport.height) {
            // Reset the effect
            tween.pause(0, false);
            return;

        }

        // Calculate base stagger
        let stagger = target.dataset.revealStagger;
        if (stagger === undefined || !stagger.length) {
            const type = target.dataset.reveal;
            stagger = defaultStaggers[type] || 0;
        }
        stagger = parseInt(stagger, 10);

        if (!isIntersecting && top < 0) {

            if (alwaysReveal) {
                tween.play();
            } else {
                tween.pause(tween.duration(), false);
            }

        } else if (isIntersecting && !tween.progress()) {

            stagger *= Math.max(0, intersecting.filter(node => getTween(node) && getTween(node)
                .progress() <= 0.05)
                .indexOf(target));

            if (!stagger) {
                tween.play();
                return;
            }

            timeout = setTimeout(() => {
                clearTimeout(timeout);
                timeouts.delete(target);
                tween.play();
            }, stagger);
        }

    });

};

const createObserver = () => {

    observer = new IntersectionObserver(onObserve, {
        threshold: [0, 0.5, 1, 0],
        rootMargin: '0px 0px 150px 0px'
    });

};

const init = () => {

    onScroll();

    createObserver();

    trackNodes();

    window.addEventListener('scroll', scrollHandler);

};

const update = () => {

    if (!observer) {
        return;
    }

    // Unobserve nodes that are no longer in the DOM
    nodes = nodes.reduce((carry, node) => {
        if (node.closest('html')) {
            return carry.concat(node);
        }
        observer.unobserve(node);
        const tween = tweens.get(node);
        if (tween) {
            tweens.delete(node);
            tween.kill();
        }
        const timeout = timeouts.get(node);
        if (timeout) {
            timeouts.delete(node);
            clearTimeout(timeout);
        }
        return carry;
    }, []);

    trackNodes();

};

export default ({
    init,
    update
});

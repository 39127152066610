import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';

import { COMPONENT_INIT, CURRENT_TIME_SET } from '../lib/events';
import { loadDateFns } from '../lib/async-bundles';
import { getTime, supportsTz } from '../lib/helpers';

export default el => {

    const timeZone = $(el).data('timezone');

    if (!timeZone || !supportsTz(timeZone)) {
        return null;
    }

    let currentTime = null;
    let DateFns = null;
    let updateTimeInterval = null;

    const clearInterval = () => {
        if (!updateTimeInterval) {
            return;
        }
        clearInterval(updateTimeInterval);
        updateTimeInterval = null;
    };

    const updateTime = () => {
        let time;
        try {
            const date = new Date(currentTime * 1000);
            const zonedDate = DateFns.utcToZonedTime(date, timeZone);
            time = DateFns.format(zonedDate, 'HH:mm', { timeZone });
        } catch (error) {
            time = null;
        }
        if (!time) {
            clearInterval();
            return;
        }
        $(el).find('[data-time]').text(time);
        el.hidden = false;
    };

    const initDateFns = () => {
        loadDateFns(({ default: dateFns }) => {
            DateFns = dateFns;
            updateTimeInterval = setInterval(() => {
                currentTime += 1;
                updateTime();
            }, 1000);
            updateTime();
        });
    };

    const onCurrentTimeSet = (key, value) => {
        currentTime = value;
        initDateFns();
    };

    const init = () => {
        getTime();
        Dispatch.on(CURRENT_TIME_SET, onCurrentTimeSet, true);
        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        Dispatch.off(CURRENT_TIME_SET, onCurrentTimeSet);
        clearInterval();
    };

    return {
        init,
        destroy
    };

};

import gsap from 'gsap';

import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Components from '../core/Components';

import { COMPONENT_INIT } from '../lib/events';
import Revealer from '../lib/Revealer';

export default el => {

    const $el = $(el);
    const tabs = $('button[role="tab"]').get();
    const panels = new WeakMap();

    tabs.forEach(tab => {
        const panel = document.getElementById(tab.getAttribute('aria-controls'));
        panels.set(panel, $(panel).html());
    });

    const keys = {
        end: 35,
        home: 36,
        left: 37,
        right: 39,
        enter: 13,
        space: 32
    };

    const activateTab = (tab, setFocus = true) => {

        const activeTab = $el.find('button[role="tab"][aria-selected="true"').get(0);

        if (activeTab === tab) {
            return;
        }

        const height = $(el).height();

        const tl = gsap.timeline({ paused: true })
            .set(el, { height, overflow: 'hidden' }, 'out');

        if (activeTab) {
            $(activeTab).attr({
                tabindex: '-1',
                'aria-selected': 'false'
            }).addClass('inactive');
            const activePanel = document.getElementById(activeTab.getAttribute('aria-controls'));
            tl
                .to(activePanel, { opacity: 0, duration: 0.3 }, 'out')
                .add(() => {
                    Components.destroy(activePanel);
                    activePanel.hidden = true;
                    $(activePanel).html(panels.get(activePanel));
                    gsap.set(activePanel, { clearProps: 'opacity' });
                }, 'in');
        }

        tl
            .add(() => {
                const panel = document.getElementById(tab.getAttribute('aria-controls'));
                panel.hidden = false;
                Components.init(panel);
                Revealer.update();
            }, 'in')
            .to(el, { height: 'auto', duration: 0.3 }, 'in')
            .set(el, { clearProps: 'height,overflow' });

        tab.removeAttribute('tabindex');
        tab.setAttribute('aria-selected', 'true');
        tab.classList.remove('inactive');

        if (setFocus) {
            tab.focus();
        }

        tl.play();

    };

    const focusFirstTab = () => {
        tabs[0].focus();
    };

    // Make a guess
    const focusLastTab = () => {
        tabs[tabs.length - 1].focus();
    };

    // Either focus the next, previous, first, or last tab
    // depending on key pressed
    const switchTabOnArrowPress = e => {

        const pressed = e.which || e.keyCode || null;
        const index = tabs.indexOf(e.target);

        if (pressed === keys.left) {
            if (index === 0) {
                focusLastTab();
            } else {
                $(tabs).eq(index - 1).focus();
            }
        } else if (pressed === keys.right) {
            if (index >= tabs.length - 1) {
                focusFirstTab();
            } else {
                $(tabs).eq(index + 1).focus();
            }
        }
    };

    // When a tab is clicked, activateTab is fired to activate it
    const onTabClick = e => {
        activateTab(e.target, false);
    };

    // Handle keydown on tabs
    const onTabKeyDown = e => {
        const key = e.which || e.keyCode || null;

        if (key === keys.end) {
            e.preventDefault();
            // Activate last tab
            focusLastTab();
        } else if (key === keys.home) {
            e.preventDefault();
            // Activate first tab
            focusFirstTab();
        }
    };

    // Handle keyup on tabs
    const onTabKeyUp = e => {
        const key = e.which || e.keyCode || null;
        if (key === keys.left || key === keys.right) {
            e.preventDefault();
            switchTabOnArrowPress(e);
        } else if (key === keys.enter || key === keys.space) {
            e.preventDefault();
            activateTab(e.target);
        }
    };

    const init = () => {

        $(tabs)
            .on('click', onTabClick)
            .on('keyup', onTabKeyUp)
            .on('keydown', onTabKeyDown);

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        $(tabs).off('click keyup keydown');
    };

    return {
        init,
        destroy
    };

};

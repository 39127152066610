import gsap from 'gsap';

import $ from '../core/Dom';
import Components from '../core/Components';

export default (html, opts = {}) => ({
    $el: null,
    $content: null,
    focusedElementBeforeOpen: null,
    afterDestroy() {},
    destroy() {
        Components.destroy(this.$el.get(0));
        this.$el.off('click');
        this.$el.remove();
        this.$el = null;
        this.content = null;
        $('body')
            .off('keyup', this.onBodyKeyUp)
            .off('focusin', this.onBodyFocus);
        if (this.focusedElementBeforeOpen) {
            this.focusedElementBeforeOpen.focus();
        }
        this.focusedElementBeforeOpen = null;
        this.afterDestroy();
        console.info('modal destroyed');
    },
    onBodyFocus(e) {
        const { target } = e;
        const content = this.$content.get(0);
        if (target === content || content.contains(target)) {
            return;
        }
        this.close();
    },
    onBodyKeyUp(e) {
        const key = e.key || e.which || e.keyCode || null;
        if (['Escape', 27].indexOf(key) === -1) {
            return;
        }
        this.close();
    },
    close() {
        gsap.timeline({
            onComplete: this.destroy
        })
            .to(this.$el.get(0), { opacity: 0, duration: 0.3 }, 0)
            .to(this.$content.get(0), { scale: 0.95, duration: 0.3, ease: 'Cubic.easeIn' }, 0);
    },
    show() {
        this.onBodyFocus = this.onBodyFocus.bind(this);
        this.onBodyKeyUp = this.onBodyKeyUp.bind(this);
        this.close = this.close.bind(this);
        this.destroy = this.destroy.bind(this);
        this.$el = $(html);
        this.$content = this.$el.find('[data-modal-content]').eq(0);
        this.$el.on('click', 'button[data-modal-closebtn]', this.close);
        const $body = $('body');
        $body.append(this.$el);
        const firstInputOrButton = this.$el.find('button,input:not([type="hidden"]),textarea,select').get(0);
        this.focusedElementBeforeOpen = document.activeElement || null;
        if (firstInputOrButton) {
            firstInputOrButton.focus();
        }
        $body.on('keyup', this.onBodyKeyUp);
        $body.on('focusin', this.onBodyFocus);
        Components.init(this.$el.get(0));
        gsap.timeline()
            .fromTo(this.$el.get(0), { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0)
            .fromTo(this.$content.get(0), { opacity: 0 }, { opacity: 1, duration: 0.3 }, 0.15)
            .fromTo(this.$content.get(0), { y: 50 }, { y: 0, duration: 0.75, ease: 'Quint.easeOut' }, 0.15);
    },
    ...opts
});

import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default el => {

    const $el = $(el);

    const onLinkClick = e => {
        const { triggerTarget: target } = e;
        e.preventDefault();
        const id = target.href.replace(/^[a-zA-Z]{3,5}\:\/{2}[a-zA-Z0-9_.:-]+\//, '').replace('brand/guidelines/', '').split('/').join('-');
        const node = $el.find(`#${id}`).get(0);
        if (!node) {
            return;
        }
        e.preventDefault();
        Viewport.scrollTo(node);
    };

    const init = () => {
        const host = window.top.location.host.split(':')[0];
        $el.on('click', `a[href*="${host.toString()}"]:not([target="_blank"])`, onLinkClick);
    };

    const destroy = () => {
        $el.off('click');
    };

    return {
        init,
        destroy
    };

};

import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default el => {

    const $el = $(el);
    const $input = $el.find('input:not([type="checkbox"]):not([type="radio"]):not([type="hidden"]),textarea,select');

    const isTextarea = $input.is('textarea');

    const setTextareaSize = () => {
        requestAnimationFrame(() => {
            const input = $input.get(0);
            if (!input) return;
            $input.height('');
            const height = Math.max($input.parent().height(), input.scrollHeight);
            $input.height(height);
        });
    };

    const onInputChange = () => {
        if ($input.val()) {
            $el.addClass('js-has-value');
        } else {
            $el.removeClass('js-has-value');
        }
        setTextareaSize();
    };

    const init = () => {
        $input.on('change keyup', onInputChange);
        onInputChange();
        if (isTextarea) {
            Viewport.on('resize', setTextareaSize);
            $input.on('keydown', setTextareaSize);
            setTextareaSize();
        }
    };

    const destroy = () => {
        $input.off('change keyup');
        if (isTextarea) {
            Viewport.off('resize', setTextareaSize);
            $input.off('keydown', setTextareaSize);
        }
    };

    return {
        init,
        destroy
    };

};

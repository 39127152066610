import gsap from 'gsap';
import ResizeObserver from 'resize-observer-polyfill';

import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default el => {

    const $el = $(el);
    const heading = $el.find('h2').get(0);
    const counter = $el.find('[data-counter]').get(0);

    let headingObserver;

    const updateCounterFontSize = () => {
        if (!counter) {
            return;
        }
        const { fontSize } = heading.firstElementChild.style;
        gsap.set(counter.firstElementChild, { fontSize });
    };

    const init = () => {
        headingObserver = new ResizeObserver(() => {
            requestAnimationFrame(() => {
                updateCounterFontSize();
            });
        });
        headingObserver.observe(heading);
        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        headingObserver.disconnect();
    };

    return {
        init,
        destroy
    };

};

import gsap from 'gsap';
import $ from './core/Dom';
import Viewport from './core/Viewport';
import Components from './core/Components';
import Dispatch from './core/Dispatch';
import lazySizes from './lib/lazysizes';
import ViewportHeight from './lib/ViewportHeight';
import FitText from './lib/fittext';
import Revealer from './lib/Revealer';
import Parallax from './lib/parallax';
import ProtectedDownloads from './lib/ProtectedDownloads';

import { COMPONENT_INIT } from './lib/events';
import { isLivePreview } from './lib/helpers';

gsap.defaults({ overwrite: 'auto' });

const init = () => {

    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    ViewportHeight.init();

    lazySizes.init();

    FitText.init();

    const isPreview = isLivePreview();
    if (!isPreview) {
        Revealer.init();
    }

    Parallax.init();

    (ProtectedDownloads()).init();

    Dispatch.on(COMPONENT_INIT, () => {
        FitText.update();
        if (!isPreview) {
            Revealer.update();
        }
    });

    // Init links
    const $body = $('body');
    const colors = [
        '#db3232', // Red
        '#3a3ae0', // Blue
        '#EEC000' // Yellow
    ];
    let prevColor = null;
    $body.on('mouseover', 'a,button:not([aria-disabled="true"]):not(.disabled)', e => {
        if (!$('html').hasClass('using-mouse')) {
            return;
        }
        const { triggerTarget: target } = e;
        const link = target.classList.contains('link') ? target : $(target).find('.link').get(0);
        if (!link || link.classList.contains('js-hover')) {
            return;
        }
        link.classList.add('js-hover');
        let color = null;
        while (!color || color === prevColor) {
            color = colors[Math.floor(Math.random() * colors.length)];
        }
        link.style.setProperty('--color', color);
        prevColor = color;
        const tl = gsap.timeline()
            .set(link, {
                backgroundPosition: '0% 100%'
            })
            .to(link, {
                backgroundSize: '100% 1px, 100% 1px',
                duration: 0.35,
                ease: 'Power2.easeInOut'
            })
            .set(link, {
                backgroundPosition: '100% 100%'
            });
        $(target).on('mouseleave', () => {
            $(target).off('mouseleave');
            link.classList.remove('js-hover');
            tl
                .to(link, {
                    backgroundSize: '0% 1px, 100% 1px',
                    duration: 0.35,
                    ease: 'Power2.easeInOut'
                })
                .set(link, { clearProps: 'backgroundSize,backgroundPosition' });
        });
    });

    $body.on('mouseover', 'a,button:not([aria-disabled="true"]):not(.disabled)', e => {
        if (!$('html').hasClass('using-mouse')) {
            return;
        }
        const { triggerTarget: target } = e;
        const link = target.classList.contains('link-alt') ? target : $(target).find('.link-alt').get(0);
        if (!link || link.classList.contains('js-hover') || link.classList.contains('is-current')) {
            return;
        }
        link.classList.add('js-hover');
        const tl = gsap.timeline()
            .set(link, {
                backgroundPosition: '0% 100%'
            })
            .to(link, {
                backgroundSize: '100% 0.07em',
                duration: 0.35,
                ease: 'Power2.easeInOut'
            })
            .set(link, {
                backgroundPosition: '100% 100%'
            });
        $(target).on('mouseleave', () => {
            $(target).off('mouseleave');
            link.classList.remove('js-hover');
            tl
                .to(link, {
                    backgroundSize: '0% 0.07em',
                    duration: 0.35,
                    ease: 'Power2.easeInOut'
                })
                .set(link, { clearProps: 'backgroundSize,backgroundPosition' });
        });
    });

    // Share links
    $body.on('click', '[data-share]', e => {
        e.preventDefault();
        const { href } = e.triggerTarget;
        const { share: network } = e.triggerTarget.dataset;
        let width;
        let height;
        switch (network) {
            case 'facebook':
                width = 585;
                height = 368;
                break;
            case 'twitter':
                width = 590;
                height = 320;
                break;
            default:
                width = 585;
                height = 511;
        }
        const left = Math.round((window.screen.width - width) / 2);
        const top = Math.round((window.screen.height - height) / 2);
        window.open(href, '', `resizable=1, scrollbars=1, fullscreen=0, height=${height}, width=${width}, left=${left}, top=${top}, toolbar=0, menubar=0, status=1`);
    });

};

require('doc-ready')(init);

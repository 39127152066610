import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default el => {
    const $el = $(el);
    return {
        init() {
            $el.on('click', e => {
                e.preventDefault();
                Viewport.scrollTo($el.attr('href'));
            });
        },
        destroy() {
            $el.off('click');
        }
    };
};

import $ from '../core/Dom';
import Viewport from '../core/Viewport';

export default el => {

    const $el = $(el);

    const onResize = () => {
        const height = $el.height();
        const parentHeight = $el.parent().height();
        if (parentHeight < Viewport.height * 0.5 || parentHeight < (height * 1.25)) {
            $el.css('position', 'relative');
        } else {
            $el.css('position', '');
        }
    };

    const init = () => {
        Viewport.on('resize', onResize);
        onResize();
    };

    const destroy = () => {
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };

};

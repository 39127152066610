import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

import Revealer from '../lib/Revealer';

export default el => {

    const $el = $(el);

    const limit = parseInt(el.dataset.limit, 10) || 4;

    const $button = $el.find('button[data-showmore-btn]');

    let canShowMore = true;

    const onBtnClick = () => {
        if (!canShowMore) {
            return;
        }
        const hiddenItems = $el.find('[data-showmore-item][hidden]').get();
        const hiddenItemsToShow = hiddenItems.slice(0, limit);
        hiddenItemsToShow.forEach(item => {
            item.hidden = false;
        });
        canShowMore = $el.find('[data-showmore-item][hidden]').get().length;
        if (!canShowMore) {
            $button.addClass('disabled');
        }
        Revealer.update();
    };

    const init = () => {
        Dispatch.emit(COMPONENT_INIT);
        $button.on('click', onBtnClick);
    };

    const destroy = () => {
        $button.off('click');
    };

    return {
        init,
        destroy
    };

};

import gsap from 'gsap';
import { loadLottie } from '../lib/async-bundles';

export default (el, props) => {

    const { jsonUrl } = props;

    if (!jsonUrl) {
        return null;
    }

    let observer;

    const initLottie = () => {
        loadLottie(Lottie => {
            const tween = gsap.fromTo(el, { opacity: 0 }, { opacity: 1, duration: 0.3 });
            const anim = Lottie.loadAnimation({
                container: el,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: jsonUrl
            });
            anim.addEventListener('data_ready', () => {
                tween.play();
            });
        });
    };

    const init = () => {
        gsap.set(el, { opacity: 0 });
        observer = new IntersectionObserver(entries => {
            const { isIntersecting } = entries[0];
            if (isIntersecting) {
                initLottie();
                observer.unobserve(el);
            }
        }, {
            treshold: 0,
            rootMargin: '500px 0px 500px 0px'
        });
        observer.observe(el);
    };

    const destroy = () => {
        observer.disconnect();
    };

    return {
        init,
        destroy
    };

};
